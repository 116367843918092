<template>
  <div>
    <div class="content" style="padding-bottom: 30px">
      <div class="title">
        <div class="span"></div>
        商品信息
      </div>
      <div class="infoLine">
        <div>商品类型</div>
        <div style="margin-left: 56px">{{ info.order_number }}</div>
      </div>
      <div class="infoLine">
        <div>商品图片</div>
        <div style="margin-left: 56px">{{ info.order_number }}</div>
      </div>
      <div class="infoLine">
        <div>商品名称</div>
        <div style="margin-left: 56px">{{ info.order_number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsInfo } from "@/api/marketing/shop/index.js";
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    product_id: Number,
    client_system_id: Number,
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.title {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  line-height: 56px;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.infoLine {
  display: flex;
  margin: 15px 0 15px 30px;
}
</style>