<template>
  <div>
    <div class="content" style="padding-bottom: 20px">
      <div class="title">
        <div class="span"></div>
        商品信息
      </div>
      <el-form
        :model="goodsInfo"
        label-width="130px"
        :rules="goodsRule"
        ref="goodsInfo"
      >
        <el-form-item label="商品类型" prop="type">
          <el-select
            placeholder="请选择商品类型"
            style="width: 300px"
            v-model="goodsInfo.type"
            @change="handleGoodsType($event)"
            :disabled="goodsInfo.status === 2 ? true : false"
          >
            <el-option label="自定义商品" :value="1"></el-option>
            <el-option label="在库商品" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片" required>
          <div style="display: flex; flex-wrap: wrap">
            <div v-if="goodsImage.length !== 0" style="display: flex">
              <div
                v-for="(item, index) in goodsImage"
                :key="index"
                style="position: relative"
              >
                <img
                  src="@/assets/images/activity/close.svg"
                  style="position: absolute; right: 3px; top: -10px"
                  @click="delGoodsImg(item)"
                  v-if="goodsInfo.status !== 2"
                />
                <img
                  :src="item.url"
                  class="avatarOther"
                  style="margin-right: 10px"
                />
              </div>
            </div>
            <div v-if="goodsImage.length < 6">
              <el-upload
                class="avatar-uploader"
                action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                :data="{
                  directory: 'AGENT_INFO',
                }"
                :headers="{
                  authorization: token,
                }"
                :disabled="goodsInfo.status === 2 ? true : false"
                :show-file-list="false"
                :on-success="handleGoodsImageSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  style="background-color: white"
                ></i>
              </el-upload>
            </div>
          </div>
          <div style="color: #909399; font-size: 12px">
            提示：至少添加一张图片，最多添加6张，默认第一张
            为展示图，建议尺寸500*500像素 图片大小1M内
          </div>
        </el-form-item>
        <el-form-item label="商品名称" prop="product_title">
          <el-input
            style="width: 300px"
            v-model="goodsInfo.product_title"
            placeholder="请输入商品名称"
            maxlength="50"
            show-word-limit
            :disabled="goodsInfo.status === 2 ? true : false"
          />
          <el-checkbox
            v-model="goodsInfo.is_recommend"
            :true-label="1"
            :false-label="0"
            style="margin-left: 10px"
            >设为推荐商品</el-checkbox
          >
          <div style="color: #909399; font-size: 14px">
            提示：商品名称2-50个字以内
          </div>
        </el-form-item>
        <el-form-item label="商品分类" required>
          <el-select
            placeholder="请选择商品分类"
            style="width: 300px"
            @change="handleType($event)"
            v-model="goodsInfo.product_class"
            filterable
            :disabled="goodsInfo.status === 2 ? true : false"
          >
            <template v-for="(item, index) in goodsClassList">
              <el-option
                :key="index"
                :label="item.product_class_name"
                :value="item.integral_store_product_class_id"
              >
              </el-option>
            </template>
          </el-select>
          <el-button
            type="primary"
            style="margin-left: 10px"
            class="el-icon-plus"
            @click="handleAddClass"
            v-if="goodsInfo.status !== 2"
            >添加分类</el-button
          >
          <div>已选分类</div>
          <div>
            <el-tag
              v-for="(tag, index) in dynamicTags"
              :key="index"
              closable
              :disable-transitions="false"
              style="margin-right: 10px"
              @close="handleCloseTag(tag)"
            >
              {{ tag.product_class_name }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="领取方式"> 到店领取 </el-form-item>
        <el-form-item label="划线价格" style="position: relative">
          <el-input
            placeholder="请输入商品划线价格"
            style="width: 300px"
            v-model="goodsInfo.line_price"
            :disabled="goodsInfo.status === 2 ? true : false"
          />
          元
          <span
            class="example"
            @mouseenter="
              () => {
                exampleDisplay = true;
              }
            "
            @mouseleave="
              () => {
                exampleDisplay = false;
              }
            "
            >查看示例</span
          >
          <img
            :src="example"
            v-if="exampleDisplay"
            style="
              position: absolute;
              top: -110px;
              margin-left: 20px;
              border: 1px solid #e8e8e8;
            "
          />
        </el-form-item>
        <el-form-item label="兑换方式" prop="trading_method">
          <el-radio-group v-model="goodsInfo.trading_method">
            <el-radio :label="1">积分兑换</el-radio>
            <el-radio :label="2">积分+人民币兑换</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="兑换限制" prop="exchange">
          <el-radio-group v-model="goodsInfo.exchange">
            <el-radio :label="0">不限</el-radio>
            <el-radio :label="1"
              >每人限兑<el-input
                v-model="goodsInfo.exchange_restrictions"
                style="margin: 0 10px; width: 88px"
                placeholder="请输入数量"
                :disabled="goodsInfo.status === 2 ? true : false"
              />件</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-dialog
        title="创建分类"
        :visible.sync="classVisiable"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
        :before-close="cancelClassDialog"
      >
        <el-form
          :model="classInfo"
          label-width="120px"
          :rules="classRule"
          ref="classInfo"
        >
          <el-form-item label="分类名称" prop="product_class_name">
            <el-input
              placeholder="请输入分类名称"
              v-model="classInfo.product_class_name"
              style="width: 60%"
              maxlength="6"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input
              placeholder="请输入排序"
              v-model="classInfo.sort"
              style="width: 60%"
              maxlength="4"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="展示设置">
            <el-checkbox
              v-model="classInfo.is_display"
              :true-label="1"
              :false-label="0"
              >首页展示</el-checkbox
            >
            <div style="color::#909399;margin-top:-5px">
              温馨提示：勾选后，该分类项在首页中展示，展示上限10个
            </div>
          </el-form-item>
          <div style="text-align: center">
            <el-button type="primary" @click="createGoodsClass()"
              >保存</el-button
            >
            <el-button @click="cancelClassDialog">取消</el-button>
          </div>
        </el-form>
      </el-dialog>
      <el-dialog
        title="添加/编辑规格参数"
        :visible.sync="specificationVisiable"
        :close-on-click-modal="false"
        width="50%"
        :before-close="cancelSpecificationDialog"
      >
        <SpecificationConfig
          @hide="getSpecificationList"
          @close="
            () => {
              specificationVisiable = false;
            }
          "
          :client_system_id="client_system_id"
        />
      </el-dialog>
    </div>
    <div class="content" style="padding-bottom: 20px; margin-top: 15px">
      <div class="title" v-if="specificationList.length !== 0">
        <div class="span"></div>
        规格明细
      </div>
      <div style="display: flex">
        <!-- <div
          style="
            width: 120px;
            text-align: right;
            color: #606266;
            line-height: 30px;
          "
        >
          产品规格
        </div> -->
        <!-- <div style="margin-left: 15px">
          <el-button
            class="el-icon-plus"
            type="primary"
            size="small"
            @click="handleAddGoodsAttr"
            >添加规格</el-button
          >
          <span
            class="example"
            style="margin-left: 5px"
            @mouseenter="
              () => {
                specificationExampleDisplay = true;
              }
            "
            @mouseleave="
              () => {
                specificationExampleDisplay = false;
              }
            "
            >查看示例</span
          >
          <img
            :src="specificationExample"
            v-if="specificationExampleDisplay"
            style="
              position: absolute;
              top: -150px;
              margin-left: 20px;
              border: 1px solid #e8e8e8;
            "
          />
          <div style="margin-top: 10px; color: #909399">
            提示：最多添加3个产品规格，每个规格最少添加1个规格参数
          </div>
        </div> -->
      </div>
      <div
        v-if="
          specificationList.length !== 0 && specificationList[0].attribute_name
        "
        style="margin-left: 130px; width: 80%"
      >
        <el-table :data="specificationList" stripe>
          <el-table-column
            label="规格"
            prop="attribute_name"
            align="center"
            width="300"
          ></el-table-column>
          <el-table-column label="参数" prop="value" align="center">
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap; margin-top: 8px">
                <div v-for="(item, index) in scope.row.value" :key="index">
                  <div v-if="item.isChoose" class="box">
                    <div
                      class="close"
                      @click="handleCloseSpecification(scope.row, item)"
                      v-if="index !== 0"
                    >
                      <img src="@/assets/images/activity/close.svg" />
                    </div>
                    {{ item.attribute_value }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            prop="action"
            align="center"
            width="300"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="
                  () => {
                    specificationVisiable = true;
                  }
                "
                >编辑</el-button
              >
              <el-button type="text" @click="handleDelSpecification(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        style="display: flex; margin-top: 10px"
        v-if="specificationList.length !== 0"
      >
        <div
          style="
            width: 120px;
            text-align: right;
            color: #606266;
            line-height: 30px;
          "
        >
          规格信息
        </div>
        <div style="margin-left: 10px; width: 80%">
          <el-table
            :data="goodsList"
            v-if="specificationList.length !== 0"
            border
            max-height="700"
          >
            <template v-for="(item, index) in goodsHead">
              <el-table-column
                :key="index"
                :label="item.label"
                :prop="item.prop"
                align="center"
                width="220"
              >
                <template slot-scope="scope">
                  <template
                    v-if="
                      item.prop === 'attr1' ||
                      ((item.prop === 'price' ||
                        item.prop === 'goodsNumber' ||
                        item.prop === 'hierarchy_list' ||
                        item.prop === 'goodsPic' ||
                        item.prop === 'goodsName' ||
                        item.prop === 'action') &&
                        specificationList.length === 1)
                    "
                  >
                    <div v-if="item.prop === 'attr1'">
                      {{ scope.row.attr1 }}
                    </div>
                    <div v-if="item.prop === 'price'">
                      <el-input
                        style="width: 65px; margin-right: 5px"
                        v-model="scope.row.integral"
                      />积分
                      <span
                        v-if="
                          goodsInfo.trading_method &&
                          goodsInfo.trading_method === 2
                        "
                        >+<el-input
                          style="width: 65px; margin-right: 5px"
                          v-model="scope.row.money"
                        />元</span
                      >
                    </div>
                    <div v-if="item.prop === 'goodsName'">
                      {{ scope.row.goodsName }}({{ scope.row.goods_number }})
                    </div>
                    <div
                      v-if="item.prop === 'goodsNumber'"
                      style="line-height: 50px !important"
                    >
                      <template
                        v-for="(store, storeIndex) in scope.row.hierarchy_list"
                      >
                        <div :key="storeIndex">
                          {{ store.name }}
                          <el-input
                            v-model="store.count"
                            :disabled="goodsInfo.type === 2 ? true : false"
                            style="margin-left: 5px; width: 60px"
                          />
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="item.prop === 'goodsPic'"
                      style="line-height: 70px !important; padding-top: 45px"
                    >
                      <el-upload
                        class="avatar-uploader"
                        action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                        :data="{
                          directory: 'AGENT_INFO',
                        }"
                        :headers="{
                          authorization: token,
                        }"
                        :show-file-list="false"
                        :on-success="
                          (res) => handleGoodsPicSuccess(res, scope.row.attr1)
                        "
                        :before-upload="beforeAvatarUpload"
                      >
                        <img
                          v-if="scope.row.goodsPic"
                          :src="scope.row.goodsPic"
                          class="avatarOther"
                        />
                        <i
                          v-else
                          class="el-icon-plus avatar-uploader-icon"
                          style="background-color: white"
                        ></i>
                      </el-upload>
                    </div>
                    <div
                      v-if="
                        item.prop === 'action' &&
                        goodsInfo.type &&
                        goodsInfo.type === 2
                      "
                    >
                      <el-button
                        type="text"
                        :disabled="product_id !== 0 && goodsInfo.status !== 1"
                        @click="handleChooseGoods(scope.row.attr1)"
                        >选择商品</el-button
                      >
                    </div>
                  </template>
                  <template
                    v-else-if="
                      item.prop === 'attr2' ||
                      ((item.prop === 'price' ||
                        item.prop === 'goodsNumber' ||
                        item.prop === 'hierarchy_list' ||
                        item.prop === 'goodsPic' ||
                        item.prop === 'goodsName' ||
                        item.prop === 'action') &&
                        !isThree &&
                        specificationList.length === 2)
                    "
                  >
                    <div
                      v-for="(child, childIndex) in scope.row.attr2"
                      :key="childIndex"
                      :style="
                        child.attr3
                          ? 'height:' +
                            40 * child.attr3[0].hierarchy_list.length +
                            'px;line-height:' +
                            40 * child.attr3[0].hierarchy_list.length +
                            'px; position: relative'
                          : 'height: ' +
                            40 * child.hierarchy_list.length +
                            'px; line-height:' +
                            40 * child.hierarchy_list.length +
                            'px; position: relative'
                      "
                      :class="
                        childIndex + 1 !== scope.row.attr2.length
                          ? 'border'
                          : ''
                      "
                    >
                      <div v-if="item.prop === 'attr2'">
                        {{ child.attribute_value }}
                      </div>
                      <div v-if="item.prop === 'goodsName'">
                        {{ child.goodsName }}({{ child.goods_number }})
                      </div>
                      <div v-if="item.prop === 'price'">
                        <el-input
                          style="width: 65px; margin-right: 5px"
                          v-model="child.integral"
                        />积分
                        <span
                          v-if="
                            goodsInfo.trading_method &&
                            goodsInfo.trading_method === 2
                          "
                          >+<el-input
                            style="width: 65px; margin-right: 5px"
                            v-model="child.money"
                          />元</span
                        >
                      </div>
                      <div
                        v-if="item.prop === 'goodsNumber'"
                        style="line-height: 50px !important"
                      >
                        <template
                          v-for="(store, storeIndex) in child.hierarchy_list"
                        >
                          <div :key="storeIndex">
                            {{ store.name }}
                            <el-input
                              v-model="store.count"
                              :disabled="goodsInfo.type === 2 ? true : false"
                              style="margin-left: 5px; width: 60px"
                            />
                          </div>
                        </template>
                      </div>
                      <div
                        v-if="item.prop === 'goodsPic'"
                        style="line-height: 70px !important"
                      >
                        <el-upload
                          class="avatar-uploader"
                          style="
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translate(0, -35%);
                          "
                          action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                          :data="{
                            directory: 'AGENT_INFO',
                          }"
                          :headers="{
                            authorization: token,
                          }"
                          :show-file-list="false"
                          :on-success="
                            (res) =>
                              handleGoodsPicSuccess(
                                res,
                                scope.row.attr1,
                                childIndex
                              )
                          "
                          :before-upload="beforeAvatarUpload"
                        >
                          <img
                            v-if="child.goodsPic"
                            :src="child.goodsPic"
                            class="avatarOther"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                            style="background-color: white"
                          ></i>
                        </el-upload>
                      </div>
                      <div
                        v-if="
                          item.prop === 'action' &&
                          goodsInfo.type &&
                          goodsInfo.type === 2
                        "
                      >
                        <el-button
                          type="text"
                          @click="
                            handleChooseGoods(
                              scope.row.attr1,
                              childIndex,
                              droIndex
                            )
                          "
                          :disabled="product_id !== 0 && goodsInfo.status !== 1"
                          >选择商品</el-button
                        >
                      </div>
                    </div>
                  </template>
                  <template
                    v-else-if="
                      item.prop === 'attr3' ||
                      ((item.prop === 'price' ||
                        item.prop === 'goodsNumber' ||
                        item.prop === 'goodsName' ||
                        item.prop === 'goodsPic' ||
                        item.prop === 'action') &&
                        isThree)
                    "
                  >
                    <div
                      v-for="(child, childIndex) in scope.row.attr2"
                      :key="childIndex"
                    >
                      <div
                        v-for="(dro, droIndex) in child.attr3"
                        :key="droIndex"
                        :style="
                          'height: ' +
                          40 * dro.hierarchy_list.length +
                          'px; line-height: ' +
                          40 * dro.hierarchy_list.length +
                          'px;position:relative'
                        "
                      >
                        <div class="border">
                          <div v-if="item.prop === 'attr3'">
                            {{ dro.attribute_value }}
                          </div>
                          <div v-if="item.prop === 'price' && isThree">
                            <el-input
                              style="width: 65px; margin-right: 5px"
                              v-model="dro.integral"
                            />积分
                            <span
                              v-if="
                                goodsInfo.trading_method &&
                                goodsInfo.trading_method === 2
                              "
                              >+
                              <el-input
                                style="width: 65px; margin-right: 5px"
                                v-model="dro.money"
                              />元</span
                            >
                          </div>
                          <div
                            v-if="item.prop === 'goodsNumber' && isThree"
                            style="line-height: 50px !important"
                          >
                            <template
                              v-for="(store, storeIndex) in dro.hierarchy_list"
                            >
                              <div :key="storeIndex">
                                {{ store.name
                                }}<el-input
                                  v-model="store.count"
                                  :disabled="
                                    goodsInfo.type === 2 ? true : false
                                  "
                                  style="margin-left: 5px; width: 60px"
                                />
                              </div>
                            </template>
                          </div>
                          <div
                            v-if="item.prop === 'goodsPic' && isThree"
                            style="line-height: 70px !important"
                          >
                            <el-upload
                              class="avatar-uploader"
                              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                              :data="{
                                directory: 'AGENT_INFO',
                              }"
                              style="
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 50%;
                                transform: translate(0, -35%);
                              "
                              :headers="{
                                authorization: token,
                              }"
                              :show-file-list="false"
                              :on-success="
                                (res) =>
                                  handleGoodsPicSuccess(
                                    res,
                                    scope.row.attr1,
                                    childIndex,
                                    droIndex
                                  )
                              "
                              :before-upload="beforeAvatarUpload"
                            >
                              <img
                                v-if="dro.goodsPic"
                                :src="dro.goodsPic"
                                class="avatarOther"
                              />
                              <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                                style="background-color: white"
                              ></i>
                            </el-upload>
                          </div>
                          <div v-if="item.prop === 'goodsName' && isThree">
                            {{ dro.goodsName }}({{ dro.goods_number }})
                          </div>
                          <div
                            v-if="
                              item.prop === 'action' &&
                              goodsInfo.type &&
                              goodsInfo.type === 2 &&
                              isThree
                            "
                          >
                            <el-button
                              type="text"
                              @click="
                                handleChooseGoods(
                                  scope.row.attr1,
                                  childIndex,
                                  droIndex
                                )
                              "
                              :disabled="
                                product_id !== 0 && goodsInfo.status !== 1
                              "
                              >选择商品</el-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div class="title">
        <div class="span"></div>
        商品详情
      </div>
      <div id="editor" class="editor" style="margin-bottom: 50px"></div>
    </div>
    <div
      style="
        padding: 15px 0;
        text-align: center;
        margin-top: 15px;
        position: fixed;
        bottom: 0;
        z-index: 2;
        border: 1px solid #e8e8e8;
      "
      class="content"
    >
      <el-button type="primary" plain @click="goBack">返回</el-button>
      <el-button
        type="primary"
        @click="submitGoodsInfo"
        v-if="goodsInfo.status !== 2"
        >保存</el-button
      >
    </div>
    <el-dialog
      :fullscreen="false"
      title="筛选货品"
      :visible.sync="chooseGoodsVisiable"
      width="90%"
      :before-close="handleClose"
      ><ChooseGoods
        @getGoods="getGoodsItem"
        v-if="chooseGoodsVisiable"
        :prizeList="prizeList"
        :type="2"
        :hierarcyhList="hierarcyhList"
    /></el-dialog>
  </div>
</template>

<script>
import {
  getGoodsClassAll,
  getHierarcyhList,
  saveGoodsInfo,
  updateGoodsInfo,
  getGoodsInfo,
  addGoodsClass,
} from "@/api/marketing/shop/index.js";
import { getGoodsByHierarchy } from "@/api/marketing/activity/index";
import SpecificationConfig from "./SpecificationConfig.vue";
import ChooseGoods from "@/views/marketing/activity/activityList/chooseGood.vue";
import Cookies from "js-cookie";
import storage from "good-storage";
import wangeditor from "wangeditor";
import example from "@/assets/images/activity/image_example.png";
import specificationExample from "@/assets/images/activity/image_example2.png";
export default {
  data() {
    return {
      example: example,
      exampleDisplay: false,
      specificationExample: specificationExample,
      specificationExampleDisplay: false,
      chooseGoodsVisiable: false,
      goodsInfo: {},
      goodsImage: [],
      token: "",
      goodsClassList: [],
      dynamicTags: [],
      classVisiable: false,
      classInfo: { product_class_name: "" },
      classRule: {
        product_class_name: [
          {
            required: true,
            message: "请填写分类名称",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur",
          },
        ],
      },
      specificationVisiable: false,
      specificationList: [],
      goodsList: [
        {
          price: null,
          goodsNumber: null,
          goodsName: null,
          goodsPic: null,
          hierarchy_list: [],
        },
      ],
      editor: null,
      prizeList: [],
      goodsHead: [],
      isThree: false,
      hierarcyhList: [],
      goodsRule: {
        type: [
          {
            required: true,
            message: "请选择商品类型",
            trigger: "change",
          },
        ],
        product_title: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
        trading_method: [
          {
            required: true,
            message: "请选择兑换方式",
            trigger: "change",
          },
        ],
        exchange: [
          {
            required: true,
            message: "请选择兑换限制",
            trigger: "change",
          },
        ],
      },
      attribute_value: "",
      childIndex: 0,
      droIndex: 0,
    };
  },
  props: {
    product_id: Number,
    client_system_id: Number,
    status: Number,
    check: Boolean,
  },
  mounted() {
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "http://api.test2.etouch.vip";
    } else {
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "http://api.test.etouch.vip";
      } else if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top";
      } else if (host === "edianbao.pretest.etouch.vipp") {
        this.baseUrl = "https://api.pretest.etouch.vip";
      } else if (host === "edianbao.dev.etouch.vip") {
        this.baseUrl = "http://api.dev.etouch.vip";
      }
    }
    this.token = storage.session.get("token") || Cookies.get("token");
    this.editor = new wangeditor("#editor");
    this.editor.config.uploadImgServer =
      "https://api.etouch.top/store/common.UploadFile/uploadOssImage";
    this.editor.config.uploadFileName = "file";
    this.editor.config.uploadImgParams = {
      directory: "AGENT_INFO",
    };
    this.editor.config.uploadImgHeaders = {
      authorization: this.token,
    };
    this.editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        insertImgFn(result.data);
      },
    };
    this.editor.create();
  },
  created() {
    if (this.product_id !== 0) {
      this.getGoodsInfo();
    }
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getGoodsClassAll();
    this.getHierarcyhList();
  },
  components: {
    SpecificationConfig,
    ChooseGoods,
  },
  methods: {
    createGoodsClass() {
      this.$refs.classInfo.validate((valid) => {
        if (valid) {
          addGoodsClass({
            ...this.classInfo,
            client_system_id: this.client_system_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                message: "新增成功！",
                type: "success",
              });
              this.cancelClassDialog();
              this.getGoodsClassAll();
            }
          });
        }
      });
    },
    getGoodsInfo() {
      getGoodsInfo({ product_id: this.product_id }).then((res) => {
        if (res.code === 1) {
          let goods_list = [];
          let specificationList = [];

          if (res.data.sku.length !== 0) {
            res.data.sku.forEach((item, index) => {
              let info = {};
              item.hierarchy = JSON.parse(item.hierarchy);
              if (item.attribute !== "") {
                item.attribute = JSON.parse(item.attribute);
                if (specificationList.length === 0) {
                  item.attribute.forEach((attr) => {
                    specificationList.push({
                      attribute_name: attr.key,
                      checked: true,
                      value: [
                        {
                          attribute_id: attr.attribute_id,
                          attribute_name: attr.value,
                        },
                      ],
                    });
                  });
                } else {
                  specificationList = specificationList.map((specifi) => {
                    item.attribute.forEach((attr) => {
                      if (attr.key === specifi.attribute_name) {
                        if (
                          !specifi.value.some(
                            (val) => val.attribute_id === attr.attribute_id
                          )
                        ) {
                          specifi.value.push({
                            attribute_id: attr.attribute_id,
                            attribute_name: attr.value,
                          });
                        }
                      }
                    });
                    return specifi;
                  });
                }
                if (index === 0) {
                  info = {
                    attr1: item.attribute[0].value,
                    attribute_value: item.attribute[0].value,
                    ...item.attribute[0],
                  };

                  if (item.attribute.length === 2) {
                    info.attr2 = [];
                    info.attr2.push({
                      attribute_value: item.attribute[1].value,
                      ...item.attribute[1],
                    });
                  }
                  if (item.attribute.length === 3) {
                    info.attr2 = [
                      {
                        attribute_value: item.attribute[1].value,
                        ...item.attribute[1],
                        attr3: [
                          {
                            attribute_value: item.attribute[2].value,
                            ...item.attribute[2],
                          },
                        ],
                      },
                    ];
                  }
                  goods_list.push(info);
                } else if (index > 0) {
                  if (
                    goods_list.length !== 0 &&
                    goods_list.some(
                      (goods) =>
                        goods.attribute_value === item.attribute[0].value
                    )
                  ) {
                    goods_list = goods_list.map((goods) => {
                      if (goods.attribute_value === item.attribute[0].value) {
                        if (
                          goods.attr2 &&
                          !goods.attr2.some(
                            (child) =>
                              child.attribute_value === item.attribute[1].value
                          )
                        ) {
                          goods.attr2.push({
                            attribute_value: item.attribute[1].value,
                            ...item.attribute[1],
                          });
                        }
                        if (item.attribute.length === 3) {
                          goods.attr2.map((child) => {
                            if (
                              child.attr3 &&
                              !child.attr3.some(
                                (child) =>
                                  child.attribute_value ===
                                  item.attribute[2].value
                              )
                            ) {
                              child.attr3.push({
                                attribute_value: item.attribute[2].value,
                                ...item.attribute[2],
                              });
                            } else {
                              child.attr3 = [];
                              child.attr3.push({
                                attribute_value: item.attribute[2].value,
                                ...item.attribute[2],
                              });
                            }
                            return child;
                          });
                        }
                      }
                      return goods;
                    });
                  } else {
                    info = {
                      attr1: item.attribute[0].value,
                      attribute_value: item.attribute[0].value,
                      ...item.attribute[0],
                    };
                    if (item.attribute.length === 2) {
                      info.attr2 = [
                        {
                          attribute_value: item.attribute[1].value,
                          ...item.attribute[1],
                        },
                      ];
                    }
                    if (item.attribute.length === 3) {
                      info.attr2 = [
                        {
                          attribute_value: item.attribute[1].value,
                          ...item.attribute[1],
                          attr3: [
                            {
                              attribute_value: item.attribute[2].value,
                              ...item.attribute[2],
                            },
                          ],
                        },
                      ];
                    }
                    goods_list.push(info);
                  }
                }
                goods_list = goods_list.map((goods, index) => {
                  if (item.attribute.length === 1) {
                    goods.price = null;
                    goods.money = item.money_price;
                    goods.integral = item.integral_price;
                    goods.goodsNumber = item.goods_number;
                    goods.goodsName = null;
                    goods.goodsPic = item.image;
                    goods.hierarchy_list = item.hierarchy;
                    goods.goods_id = item.goods_id;
                    goods.goods_number = item.goods_number;
                    goods.goodsName = item.goods_name;
                  }
                  if (item.attribute.length === 2) {
                    goods.attr2.map((child, childIndex) => {
                      console.log(childIndex, index);
                      child.price = null;
                      child.money = item.money_price;
                      child.integral = item.integral_price;
                      child.goodsNumber = item.goods_number;
                      child.goodsName = null;
                      child.goodsPic = item.image;
                      child.hierarchy_list = item.hierarchy;
                      child.goods_id = item.goods_id;
                      child.goods_number = item.goods_number;
                      child.goodsName = item.goods_name;
                      return child;
                    });
                  }
                  if (item.attribute.length === 3) {
                    this.isThree = true;
                    goods.attr2.map((child, childIndex) => {
                      child.attr3 = JSON.parse(
                        JSON.stringify(
                          child.attr3.map((dro, droIndex) => {
                            dro.price = null;
                            dro.money = item.money_price;
                            dro.integral = item.integral_price;
                            dro.goodsNumber = item.goods_number;
                            dro.goodsName = null;
                            dro.goodsPic = item.image;
                            dro.hierarchy_list = item.hierarchy;
                            dro.goods_id = item.goods_id;
                            dro.goods_number = item.goods_number;
                            dro.goodsName = item.goods_name;
                            return dro;
                          })
                        )
                      );
                      return child;
                    });
                  }
                  return goods;
                });
              } else {
                specificationList = [{}];
                goods_list.push({
                  price: null,
                  money: item.money_price,
                  integral: item.integral_price,
                  goodsNumber: item.goods_number,
                  goodsName: null,
                  goodsPic: item.image,
                  hierarchy_list: item.hierarchy,
                  goods_id: item.goods_id,
                  goods_number: item.goods_number,
                  goodsName: item.goods_name,
                });
              }
            });
          }

          if (res.data.sku[0].attribute !== "") {
            res.data.sku[0].attribute.forEach((attr, attrIndex) => {
              let info = {};
              info["prop"] = "attr" + (attrIndex + 1);
              info["label"] = attr.key;
              this.goodsHead.push(info);
            });
          }

          this.goodsHead = this.goodsHead.concat(
            res.data.type && res.data.type === 1
              ? [
                  { prop: "price", label: "商品价格" },
                  { prop: "goodsPic", label: "商品图片" },
                  { prop: "goodsNumber", label: "商品数量" },
                ]
              : [
                  { prop: "price", label: "商品价格" },
                  { prop: "goodsNumber", label: "商品数量" },
                  { prop: "goodsName", label: "已选在库商品" },
                  { prop: "goodsPic", label: "商品图片" },
                  { prop: "action", label: "操作" },
                ]
          );

          this.specificationList = specificationList;
          this.goodsList = goods_list;
          this.$set(this.goodsList);
          res.data.exchange_restrictions === 0
            ? (res.data.exchange = 0)
            : (res.data.exchange = 1);
          this.goodsInfo = res.data;
          this.dynamicTags = res.data.class;
          this.goodsImage = res.data.images.map((item) => {
            const info = {};
            info.url = item;
            return info;
          });
          this.editor.txt.append(JSON.parse(res.data.content));
        }
      });
    },
    handleGoodsPicSuccess(res, name, childIndex, droIndex) {
      this.goodsList = this.goodsList.map((item) => {
        if (item.attr1 === name) {
          if (item.attr2 && item.attr2.length !== 0) {
            item.attr2.map((child, childIndexs) => {
              if (childIndex === childIndexs) {
                if (child.attr3 && child.attr3.length !== 0) {
                  child.attr3.map((dro, droI) => {
                    if (droIndex === droI) {
                      dro.goodsPic = res.data;
                    }
                    return dro;
                  });
                } else {
                  child.goodsPic = res.data;
                }
              }
              return child;
            });
          }

          item.goodsPic = res.data;
        }
        return item;
      });
      this.$set(this.goodsList);
    },

    getHierarcyhList() {
      getHierarcyhList({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            if (res.data.length !== 0) {
              this.hierarcyhList = JSON.parse(
                JSON.stringify(res.data.filter((item) => item.status === 1))
              ).map((item) => {
                item.count = null;
                return item;
              });
            }
          }
        }
      );
    },
    goBack() {
      this.goodsInfo = {};
      this.goodsList = [
        {
          price: null,
          goodsNumber: null,
          goodsName: null,
          goodsPic: null,
          hierarchy_list: [],
        },
      ];
      this.$emit("hide");
    },
    getGoodsItem(data) {
      this.hierarcyhList = this.hierarcyhList.map((item, index) => {
        getGoodsByHierarchy({
          hierarchy_id: item.hierarchy_id,
          goods_number: data.goods_number,
        }).then((res) => {
          if (res.data.length !== 0) {
            if (item.hierarchy_id === res.data[0].hierarchy_id) {
              item.count = res.data[0].g_count;
            }
          } else {
            item.count = 0;
          }
        });
        return item;
      });
      console.log(this.hierarcyhList);
      this.goodsList = this.goodsList.map((item) => {
        if (item.attr1 === this.attribute_value) {
          if (item.attr2) {
            item.attr2.map((child, childIndex) => {
              if (childIndex === this.childIndex) {
                if (child.attr3) {
                  child.attr3.map((dro, droI) => {
                    if (droI === this.droIndex) {
                      dro.goodsPic = data.goods_pic;
                      dro.goods_id = data.goods_id;
                      dro.goods_number = data.goods_number;
                      dro.goodsName = data.goods_name;
                      dro.hierarchy_list = this.hierarcyhList;
                    }
                    return dro;
                  });
                } else {
                  child.goodsPic = data.goods_pic;
                  child.goods_id = data.goods_id;
                  child.goods_number = data.goods_number;
                  child.goodsName = data.goods_name;
                  child.hierarchy_list = this.hierarcyhList;
                }
              }
              return child;
            });
          } else {
            item.goodsPic = data.goods_pic;
            item.goods_id = data.goods_id;
            item.goods_number = data.goods_number;
            item.goodsName = data.goods_name;
            item.hierarchy_list = this.hierarcyhList;
          }
        }
        return item;
      });
      this.$set(this.goodsList);
      this.chooseGoodsVisiable = false;
    },
    handleClose() {
      this.chooseGoodsVisiable = false;
    },
    handleChooseGoods(attr, childIndex, droIndex) {
      this.attribute_value = attr;
      this.childIndex = childIndex;
      this.droIndex = droIndex;
      this.chooseGoodsVisiable = true;
    },
    getSpecificationList(row) {
      let specificationList = JSON.parse(JSON.stringify(row));
      this.goodsList = [];
      this.goodsHead = [];
      this.specificationVisiable = false;
      let arr = [];
      arr = specificationList[0].value
        .filter((item) => item.isChoose === true)
        .map((item) => {
          item.attr1 = item.attribute_value;
          item.key = specificationList[0].attribute_name;
          return item;
        });
      if (specificationList.length === 1) {
        arr = arr.map((item) => {
          item.price = null;
          item.money = null;
          item.integral = null;
          item.goodsNumber = null;
          item.goodsName = null;
          item.goodsPic = null;
          item.hierarchy_list = JSON.parse(JSON.stringify(this.hierarcyhList));
          return item;
        });
      } else if (specificationList.length === 2) {
        arr = arr.map((child, i) => {
          child["attr" + 2] = [];
          child["attr" + 2] = specificationList[1].value
            .filter((item) => item.isChoose === true)
            .map((item) => {
              item.key = specificationList[1].attribute_name;
              return item;
            });
          child["attr" + 2] = JSON.parse(
            JSON.stringify(
              child["attr" + 2].map((item) => {
                item.price = null;
                item.money = null;
                item.integral = null;
                item.goodsNumber = null;
                item.goodsName = null;
                item.goodsPic = null;
                item.hierarchy_list = JSON.parse(
                  JSON.stringify(this.hierarcyhList)
                );
                return item;
              })
            )
          );
          return child;
        });
      } else if (specificationList.length === 3) {
        this.isThree = true;
        arr = arr.map((item) => {
          item["attr" + 2] = [];
          item["attr" + 2] = specificationList[1].value
            .filter((i) => i.isChoose === true)
            .map((item) => {
              item.key = specificationList[1].attribute_name;
              return item;
            });
          item["attr" + 2] = JSON.parse(
            JSON.stringify(
              item["attr" + 2].map((child) => {
                child["attr" + 3] = [];
                child["attr" + 3] = specificationList[2].value
                  .filter((i) => i.isChoose === true)
                  .map((item) => {
                    item.key = specificationList[2].attribute_name;
                    return item;
                  });
                child["attr" + 3] = JSON.parse(
                  JSON.stringify(
                    child["attr" + 3].map((item) => {
                      item.price = null;
                      item.money = null;
                      item.integral = null;
                      item.goodsNumber = null;
                      item.goodsName = null;
                      item.goodsPic = null;
                      item.hierarchy_list = JSON.parse(
                        JSON.stringify(this.hierarcyhList)
                      );
                      return item;
                    })
                  )
                );
                return child;
              })
            )
          );

          return item;
        });
      }

      specificationList.forEach((item, index) => {
        const info = {};
        info["prop"] = "attr" + (index + 1);
        info["label"] = item.attribute_name;
        this.goodsHead.push(info);
      });
      this.goodsHead = this.goodsHead = this.goodsHead.concat(
        this.goodsInfo.type && this.goodsInfo.type === 1
          ? [
              { prop: "price", label: "商品价格" },
              { prop: "goodsPic", label: "商品图片" },
              { prop: "goodsNumber", label: "商品数量" },
            ]
          : [
              { prop: "price", label: "商品价格" },
              { prop: "goodsNumber", label: "商品数量" },
              { prop: "goodsName", label: "已选在库商品" },
              { prop: "goodsPic", label: "商品图片" },
              { prop: "action", label: "操作" },
            ]
      );
      this.goodsList = arr;
      this.$set(this.goodsList);
      this.specificationList = specificationList;
    },
    handleAddGoodsAttr() {
      if (!this.goodsInfo.type) {
        this.$message({
          message: "请选择商品类型",
          type: "warning",
        });
      } else {
        this.isThree = false;
        this.goodsList = [];
        this.goodsHead = [];
        this.specificationList = [];
        this.specificationVisiable = true;
      }
    },
    delGoodsImg(row) {
      this.goodsImage = this.goodsImage.filter((item) => item.url !== row.url);
    },
    handleGoodsType(event) {
      this.goodsHead =
        this.goodsInfo.type && this.goodsInfo.type === 1
          ? [
              { prop: "price", label: "商品价格" },
              { prop: "goodsPic", label: "商品图片" },
              { prop: "goodsNumber", label: "商品数量" },
            ]
          : [
              { prop: "price", label: "商品价格" },
              { prop: "goodsNumber", label: "商品数量" },
              { prop: "goodsName", label: "已选在库商品" },
              { prop: "goodsPic", label: "商品图片" },
              { prop: "action", label: "操作" },
            ];
      if (event === 1) {
        this.specificationList = [
          {
            price: null,
            money: null,
            hierarchy_list: JSON.parse(JSON.stringify(this.hierarcyhList)),
            goodsPic: null,
          },
        ];
        this.goodsList = [
          {
            price: null,
            money: null,
            hierarchy_list: JSON.parse(JSON.stringify(this.hierarcyhList)),
            goodsPic: null,
            goodsName: null,
          },
        ];
      } else {
        this.specificationList = [
          {
            price: null,
            money: null,
            hierarchy_list: JSON.parse(JSON.stringify(this.hierarcyhList)),
            goodsPic: null,
          },
        ];
        this.goodsList = [
          {
            price: null,
            money: null,
            hierarchy_list: JSON.parse(JSON.stringify(this.hierarcyhList)),
            goodsPic: null,
          },
        ];
      }
      this.goodsList = JSON.parse(JSON.stringify(this.goodsList));
      this.$set(this.goodsList);
    },
    handleAddClass() {
      this.classVisiable = true;
    },
    cancelClassDialog() {
      this.classVisiable = false;
      this.classInfo = {
        name: "",
      };
    },
    getGoodsClassAll() {
      getGoodsClassAll({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.goodsClassList = res.data;
          }
        }
      );
    },
    handleGoodsImageSuccess(res, file) {
      this.goodsImage.push({ url: res.data });
    },
    beforeAvatarUpload(file) {
      const isImg = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isImg) {
        this.$message.error("图片格式只能JPG格式或者PNG格式");
      }

      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt1M && isImg;
    },
    handleType(event) {
      this.goodsClassList.forEach((item) => {
        if (item.integral_store_product_class_id === event) {
          this.dynamicTags.push({
            isAdd: true,
            integral_store_product_class_id: event,
            product_class_name: item.product_class_name,
          });
        }
      });
      let newArr = [];
      let obj = {};
      this.dynamicTags.forEach((item) => {
        const { integral_store_product_class_id } = item;
        if (!obj[integral_store_product_class_id]) {
          obj[integral_store_product_class_id] = true;
          newArr.push(item);
        }
      });
      this.dynamicTags = newArr;
    },
    /*
     *  删除所选标签
     */
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    cancelSpecificationDialog() {
      this.specificationVisiable = false;
    },
    submitGoodsInfo() {
      this.$refs.goodsInfo.validate((valid) => {
        if (valid) {
          if (this.goodsImage.length === 0) {
            this.$message({
              message: "请上传商品图片",
              type: "warning",
            });
            return;
          }
          if (this.dynamicTags.length === 0) {
            this.$message({
              message: "请选择商品分类",
              type: "warning",
            });
            return;
          }
          const data = {
            client_system_id: this.client_system_id,
            type: this.goodsInfo.type,
            product_title: this.goodsInfo.product_title,
            is_recommend: this.goodsInfo.is_recommend,
            line_price: this.goodsInfo.line_price,
            trading_method: this.goodsInfo.trading_method,
            exchange_restrictions:
              this.goodsInfo.exchange === 0
                ? 0
                : this.goodsInfo.exchange_restrictions,
            content: JSON.stringify(this.editor.txt.html()),
          };

          var sku_list = [];
          this.goodsList.forEach((item) => {
            var attribute_listOne = [];
            attribute_listOne.push({
              key: item.key,
              value: item.attribute_value,
              attribute_id: item.attribute_id,
            });
            if (item.attr2) {
              item.attr2.forEach((child) => {
                var attribute_listTwo = [
                  {
                    key: item.key,
                    value: item.attribute_value,
                    attribute_id: item.attribute_id,
                  },
                ];
                attribute_listTwo.push({
                  key: child.key,
                  value: child.attribute_value,
                  attribute_id: child.attribute_id,
                });
                if (child.attr3) {
                  child.attr3.forEach((grand) => {
                    var attribute_listThree = [
                      {
                        key: item.key,
                        value: item.attribute_value,
                        attribute_id: item.attribute_id,
                      },
                      {
                        key: child.key,
                        value: child.attribute_value,
                        attribute_id: child.attribute_id,
                      },
                    ];
                    attribute_listThree.push({
                      key: grand.key,
                      value: grand.attribute_value,
                      attribute_id: grand.attribute_id,
                    });
                    sku_list.push({
                      attribute_list: attribute_listThree,
                      money: grand.money,
                      integral: grand.integral,
                      image: grand.goodsPic,
                      goods_id: grand.goods_id,
                      goods_number: grand.goods_number,
                      goods_name: grand.goodsName,
                      hierarchy_list: grand.hierarchy_list.map((store) => {
                        return {
                          count: store.count,
                          hierarchy_id: store.hierarchy_id,
                          name: store.name,
                        };
                      }),
                    });
                  });
                } else {
                  sku_list.push({
                    attribute_list: attribute_listTwo,
                    money: child.money,
                    integral: child.integral,
                    image: child.goodsPic,
                    goods_id: child.goods_id,
                    goods_number: child.goods_number,
                    goods_name: child.goodsName,
                    hierarchy_list: child.hierarchy_list.map((store) => {
                      return {
                        count: store.count,
                        hierarchy_id: store.hierarchy_id,
                        name: store.name,
                      };
                    }),
                  });
                }
              });
            } else {
              sku_list.push({
                attribute_list: attribute_listOne,
                money: item.money,
                integral: item.integral,
                image: item.goodsPic,
                goods_id: item.goods_id,
                goods_number: item.goods_number,
                goods_name: item.goodsName,
                hierarchy_list: item.hierarchy_list.map((store) => {
                  return {
                    count: store.count,
                    hierarchy_id: store.hierarchy_id,
                    name: store.name,
                  };
                }),
              });
            }
          });

          data.images = this.goodsImage.map((item) => {
            return item.url;
          });
          data.product_class = this.dynamicTags.map((item) => {
            return item.integral_store_product_class_id;
          });
          data.sku_list = JSON.stringify(sku_list);
          if (this.product_id === 0) {
            saveGoodsInfo(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.goBack();
              }
            });
          } else {
            data.product_id = this.product_id;
            updateGoodsInfo(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "更新成功",
                  type: "success",
                });
                this.goBack();
              }
            });
          }
        }
      });
    },
    handleCloseSpecification(row, data) {
      this.$confirm("此操作将删除该规格参数, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.specificationList = this.specificationList.map((item) => {
          if (item.attribute_name === row.attribute_name) {
            item.value = item.value.map((child) => {
              if (child.attribute_value === data.attribute_value) {
                child.isChoose = false;
              }
              return child;
            });
          }
          return item;
        });
        this.getSpecificationList(this.specificationList);
      });
    },
    handleDelSpecification(row) {
      this.$confirm("此操作将删除该规格参数, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.specificationList = this.specificationList.filter(
          (item) => item.attribute_name !== row.attribute_name
        );
        this.getSpecificationList(this.specificationList);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.example {
  color: #1991ff;
}
.example:hover {
  cursor: pointer;
}
.title {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  line-height: 56px;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}

/deep/ .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 70px;
  height: 70px;
}
/deep/.avatar-uploader .el-upload {
  width: 70px;
  height: 70px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
// /deep/ .el-upload--picture-card {
//   line-height: 115px !important;
// }

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

/deep/ .avatarOther {
  width: 70px;
  height: 70px;
  display: block;
}
.border {
  border-bottom: 1px solid #ddd;
}
/deep/ .el-table__row .cell {
  padding: 0;
}

/deep/.el-table .success-row {
  background: #f5f7fa;
}
.editor {
  margin: 0 0 0 130px;
  width: 50%;
  min-height: 400px; //编辑框最小高度
  height: auto; //编辑框高度超过最小高度会根据内容高度自适应
  z-index: 10 !important;
}

.box {
  position: relative;
  margin: 0px 17px 8px 0;
  padding: 5px 10px;
  border: 1px solid #909399;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  .close {
    position: absolute;
    top: -8px;
    right: -12px;
    width: 18px;
    height: 18px;
  }
}
/deep/ .w-e-toolbar {
  z-index: 1 !important;
}
/deep/ .w-e-text-container {
  z-index: 0 !important;
}
</style>
