<template>
  <div style="display: flex">
    <div class="h5View">
      <div style="z-index: 9999">
        <iframe
          v-if="this.info.url"
          :src="this.info.url + '/2'"
          width="402"
          height="800"
          style="margin: 0 auto"
        ></iframe>
      </div>
    </div>
    <div class="content" style="margin-left: 10px">
      <div class="title">
        <div class="span"></div>
        商城信息
      </div>
      <div style="display: flex; margin: 0 0 24px 30px">
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">关联体系</div>
          <div>{{ info.client_system_title }}</div>
        </div>
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">创建人</div>
          <div>{{ info.create_client_user }}</div>
        </div>
      </div>
      <div style="display: flex; margin: 0 0 24px 30px">
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">商城名称</div>
          <div>{{ info.integral_store_name }}</div>
        </div>
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">创建时间</div>
          <div>{{ info.create_time }}</div>
        </div>
      </div>
      <div style="display: flex; margin: 0 0 24px 30px">
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">状态</div>
          <div>
            <el-switch
              v-model="info.status"
              :active-value="1"
              :inactive-value="0"
              @change="handleUpdateStatus"
            ></el-switch>
          </div>
        </div>
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">最后修改时间</div>
          <div>{{ info.update_time }}</div>
        </div>
      </div>
      <div style="display: flex; margin: 0 0 24px 30px">
        <div style="width: 40%; display: flex">
          <div style="width: 50%; color: #909399">商品数量</div>
          <div>{{ info.product_count }}</div>
        </div>
      </div>
      <div class="link" @click="handleLink">
        <img
          src="@/assets/images/activity/link.svg"
          style="width：24px;height:24px"
        />商城链接
      </div>
    </div>
    <el-dialog
      title="商城链接"
      :visible.sync="visiable"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
      :before-close="cancelDialog"
    >
      <PreviewLink :info="info" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getShopConfigInfo,
  updateStatusConfig,
} from "@/api/marketing/shop/index.js";
import PreviewLink from "./PreviewLink.vue";
export default {
  data() {
    return { info: {}, visiable: false };
  },
  props: {
    client_system_id: Number,
  },
  components: {
    PreviewLink,
  },
  mounted() {
    this.getShopConfigInfo();
  },
  methods: {
    cancelDialog() {
      this.visiable = false;
    },
    getShopConfigInfo() {
      getShopConfigInfo({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.info = res.data;
          }
        }
      );
    },
    handleLink() {
      this.visiable = true;
    },
    handleUpdateStatus() {
      updateStatusConfig({
        status: this.info.status,
        client_system_id: this.client_system_id,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "更新成功",
            type: "success",
          });
          this.getShopConfigInfo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.h5View {
  padding: 30px 80px 0 80px;
  height: 87vh;
  background: white;
  border-radius: 6px;
}

.title {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  line-height: 56px;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.link {
  margin: 30px auto 0 auto;
  width: 280px;
  height: 48px;
  border-radius: 6px;
  background: rgba(25, 145, 255, 1);
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  color: white;
  img {
    margin-right: 10px;
    vertical-align: middle;
  }
}
.link:hover {
  cursor: pointer;
}
.qrcode {
  padding: 40px;
  width: 220px;
  height: 220px;
  opacity: 1;
  border: 1px solid #e2e2e2;
  border-radius: 5px 5px 0 0;
}
</style>
