<template>
  <div>
    <div class="qrcode">
      <div id="qrCodeDiv" ref="qrCodeDiv"></div>
    </div>
    <div style="margin: 18px 0 0 0; text-align: center">
      <el-button type="primary" class="el-icon-download" @click="dowload"
        >下载商城二维码</el-button
      >
    </div>
    <div style="display: flex; margin: 0 auto 0 auto; width: 300px">
      <div class="link">
        {{ info.url }}
      </div>
      <div
        class="copy"
        data-clipboard-text="复制"
        @click="
          copylogistics(info.url + '/1/' + Number(userInfo.hierarchy_id))
        "
      >
        复制
      </div>
    </div>
    <div
      style="
        margin-top: 10px;
        text-align: center;
        color: #909399;
        font-size: 14px;
      "
    >
      您可以将链接复制到您的公众号菜单或自定义回复中
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard"; //点击复制文本内容
import QRCode from "qrcodejs2";
import storage from "good-storage";
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  props: {
    info: Object,
  },
  mounted() {
    this.userInfo = storage.get("userInfo");
    this.handleQRCode();
  },
  methods: {
    handleQRCode() {
      this.qrcode = new QRCode("qrCodeDiv", {
        text: this.info.url + "/1/" + Number(this.userInfo.hierarchy_id),
        width: 220,
        height: 220,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    /*
     *  下载二维码
     */
    dowload() {
      let myCanvas = document
        .getElementById("qrCodeDiv")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = "二维码";
      a.click();
      this.$message({
        message: "正在进行下载保存",
        type: "success",
      });
    },
    //复制功能
    copylogistics(data) {
      let clipboard = new Clipboard(".copy", {
        //绑定数据标签的类名
        text: function () {
          return data;
        },
      });
      clipboard.on("success", (e) => {
        this.$message({
          type: "success",
          message: "复制成功！",
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message({
          type: "success",
          message: "该浏览器或手机权限不支持复制功能",
        });

        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.qrcode {
  margin: 0 auto;
  padding: 10px;
  width: 220px;
  height: 220px;
  border-radius: 6px;
  border: 1px solid #c4cfdf;
}
.link {
  margin-top: 20px;
  width: 345px;
  padding: 3px 0 3px 14px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px 0px 0 5px;
  color: #909399;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.copy {
  margin-top: 20px;
  width: 60px;
  padding: 3px 0 3px 0;
  opacity: 1;
  background: rgba(39, 145, 255, 0.06);
  border: 1px solid #2791ff;
  border-radius: 0 5px 5px 0;
  text-align: center;
  color: #2791ff;
}
.copy:hover {
  cursor: pointer;
}
</style>
