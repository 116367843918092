<template>
  <div>
    <div class="top">
      <el-page-header @back="goBack" content="订单详情"> </el-page-header>
    </div>
    <div class="content" style="padding: 20px 0; border-radius: 6px">
      <div class="orderFlow">
        <template v-for="(item, index) in flowList">
          <div :key="index" style="display: flex">
            <div class="flowIcon">
              <img
                src="@/assets/images/activity/icon_complete.svg"
                v-if="item.isExistence"
              />
              <img
                src="@/assets/images/activity/icon_unfinished.svg"
                v-if="!item.isExistence"
              />
            </div>
            <div
              class="line"
              v-if="index + 1 !== flowList.length"
              :style="
                item.isExistence
                  ? ' background-color: #1991ff'
                  : 'background-color:#909399'
              "
            ></div>
          </div>
        </template>
      </div>
      <div class="flowTip">
        <template v-for="(item, index) in flowList">
          <div
            class="tipBox"
            :key="index"
            :style="index !== 0 ? 'margin-left:-18px' : null"
          >
            <div :style="item.isExistence ? 'color:#1991FF' : null">
              {{ item.name }}
            </div>
            <div style="color: #909399">{{ item.time || "--" }}</div>
          </div>
        </template>
      </div>
    </div>
    <div
      style="
        margin-top: 10px;
        padding: 20px 0 30px 30px;
        border-radius: 6px;
        background: white;
      "
    >
      <div class="title">
        <div class="span"></div>
        订单详情
      </div>
      <div style="display: flex">
        <div>订单编号</div>
        <div style="margin-left: 56px">{{ info.order_number }}</div>
      </div>
      <div style="display: flex; margin-top: 24px">
        <div>订单状态</div>
        <div style="margin-left: 56px">
          {{ orderStatus[info.order_status] }}
        </div>
      </div>
      <div
        style="
          margin: 32px 0;
          width: 500px;
          height: 1px;
          background: rgba(240, 241, 245, 1);
        "
      ></div>
      <div class="title">
        <div class="span"></div>
        买家信息
      </div>
      <div style="display: flex; width: 70%">
        <div style="display: flex; width: 25%">
          <div style="width: 30%; color: #909399">会员名称</div>
          <div>{{ info.member_name }}</div>
        </div>
        <div style="display: flex; width: 25%">
          <div style="width: 30%; color: #909399">手机号</div>
          <div>{{ info.member_phone || "--" }}</div>
        </div>
        <div style="display: flex; width: 25%">
          <div style="width: 30%; color: #909399">买家留言</div>
          <div>{{ info.remark || "--" }}</div>
        </div>
      </div>
      <div style="display: flex; width: 70%; margin-top: 15px">
        <div style="display: flex; width: 25%">
          <div style="width: 30%; color: #909399">下单时间</div>
          <div>{{ info.create_time || "--" }}</div>
        </div>
        <div style="display: flex; width: 25%">
          <div style="width: 30%; color: #909399">支付时间</div>
          <div>{{ info.pay_time || "--" }}</div>
        </div>
        <div style="display: flex; width: 25%">
          <div style="width: 30%; color: #909399">核销时间</div>
          <div>{{ info.verification_time || "--" }}</div>
        </div>
      </div>
      <div
        style="
          margin: 32px 0;
          width: 500px;
          height: 1px;
          background: rgba(240, 241, 245, 1);
        "
      ></div>
      <div class="title">
        <div class="span"></div>
        商品信息
      </div>
      <el-table :data="goodsList" border style="width: 70%">
        <el-table-column label="商品信息" align="center">
          <template slot-scope="scope">
            <div style="display: flex; margin: 8px 0">
              <div style="width: 56px; height: 56px">
                <img :src="scope.row.image" style="width: 56px; height: 56px" />
              </div>
              <div style="margin-left: 10px">
                <div style="display: flex">{{ scope.row.product_title }}</div>
                <div style="display: flex">
                  <div>分类：</div>
                  <template v-for="(item, index) in scope.row.class">
                    <div :key="index" style="margin-right: 3px">{{ item }}</div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="订单价格"
          align="center"
          prop="order_price"
        ></el-table-column>
        <el-table-column
          label="购买数量"
          align="center"
          prop="goods_total"
        ></el-table-column>
        <el-table-column
          label="领取门店"
          align="center"
          prop="hierarchy_name"
        ></el-table-column>
        <el-table-column
          label="在库商品"
          align="center"
          prop="goods_name"
          v-if="info.type === 2"
        >
          <template slot-scope="scope">
            {{ scope.row.goods_name }}({{ scope.row.goods_number }})
          </template>
        </el-table-column>
        <el-table-column
          label="核销状态"
          align="center"
          prop="verification_time"
          v-if="info.pay_time !== ''"
        >
          <template slot-scope="scope">
            {{ scope.row.verification_time === "" ? "未核销" : "已核销" }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          prop="action"
          v-if="info.pay_time !== ''"
        >
          <template>
            <el-button
              type="text"
              @click="
                () => {
                  visiable = true;
                }
              "
              >{{ info.verification_time === "" ? "核销" : "详情" }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <div
        style="
          margin: 32px 0;
          width: 500px;
          height: 1px;
          background: rgba(240, 241, 245, 1);
        "
      ></div>
      <div class="title">
        <div class="span"></div>
        账单信息
      </div> -->
      <el-dialog
        :visible.sync="visiable"
        width="30%"
        title="核销详情"
        :destroy-on-close="true"
        :before-close="cancelVerifer"
      >
        <div class="title">
          <div class="span"></div>
          会员信息
        </div>
        <div style="display: flex">
          <div style="display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">会员名称</div>
            <div>{{ info.member_name }}</div>
          </div>
          <div style="display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">手机号</div>
            <div>{{ info.member_phone }}</div>
          </div>
        </div>
        <div class="title" style="margin-top: 15px">
          <div class="span"></div>
          商品信息
        </div>
        <div>
          <div style="display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">商品名称</div>
            <div>{{ info.product_title }}</div>
          </div>
        </div>
        <div>
          <div style="margin-top: 15px; display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">商品图片</div>
            <div>
              <img
                :src="info.image"
                style="width: 94px; height: 64px; object-fit: cover"
              />
            </div>
          </div>
        </div>
        <div>
          <div style="margin-top: 15px; display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">领取门店</div>
            <div>{{ info.hierarchy_name }}</div>
          </div>
        </div>
        <div class="title" style="margin-top: 15px">
          <div class="span"></div>
          订单信息
        </div>
        <div>
          <div style="display: flex; width: 50%">
            <div style="color: #909399; margin-right: 35px">订单号</div>
            <div>{{ info.order_number }}</div>
          </div>
        </div>
        <div>
          <div style="margin-top: 15px; display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">支付金额</div>
            <div>{{ info.order_price }}</div>
          </div>
        </div>
        <div>
          <div style="margin-top: 15px; display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">支付时间</div>
            <div>{{ info.pay_time }}</div>
          </div>
        </div>
        <div>
          <div style="margin-top: 15px; display: flex; width: 50%">
            <div style="color: #909399; margin-right: 20px">下单时间</div>
            <div>{{ info.create_time }}</div>
          </div>
        </div>
        <div style="position: absolute; top: 35%; right: 10%; z-index: 5555">
          <img
            src="@/assets/images/activity/writeOffed.svg"
            style="transform: rotate(-10deg)"
            v-if="info.verification_time !== ''"
          />
          <img
            src="@/assets/images/activity/writeOff.svg"
            style="transform: rotate(-10deg)"
            v-if="info.verification_time === ''"
          />
        </div>
        <div style="margin-top: 15px; text-align: center">
          <el-button
            type="primary"
            plain
            @click="cancelVerifer"
            v-if="info.verification_time === ''"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="handleWriteOff"
            v-if="info.verification_time === ''"
            >核销</el-button
          >
          <el-button
            type="primary"
            @click="cancelVerifer"
            v-if="info.verification_time !== ''"
            >确认</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  orderDetails,
  checkVerificationCode,
} from "@/api/marketing/shop/index.js";
export default {
  data() {
    return {
      info: {},
      orderStatus: {
        10: "待付款",
        20: "待领取",
        30: "已发货",
        40: "已收货",
        50: "已领取",
        60: "已关闭",
        80: "已过期",
      },
      goodsList: [],
      flowList: [],
      visiable: false,
    };
  },
  props: {
    client_system_id: Number,
    order_id: Number,
  },
  created() {
    this.getOrderInfo();
  },
  methods: {
    goBack() {
      this.$emit("hide");
    },

    getOrderInfo() {
      orderDetails({
        client_system_id: this.client_system_id,
        order_id: this.order_id,
      }).then((res) => {
        if (res.code === 1) {
          this.goodsList = [];
          if (
            res.data.order_status === 10 ||
            res.data.order_status === 20 ||
            res.data.order_status === 50
          ) {
            this.flowList = [
              {
                name: "待付款",
                time: res.data.create_time,
                isExistence: true,
              },
              {
                name: "已付款",
                time: res.data.pay_time,
                isExistence:
                  res.data.order_status === 20 || res.data.order_status === 50
                    ? true
                    : false,
              },
              {
                name: "待领取",
                time: res.data.pay_time,
                isExistence:
                  res.data.order_status === 20 || res.data.order_status === 50
                    ? true
                    : false,
              },
              {
                name: "交易完成",
                time: res.data.complete_time,
                isExistence: res.data.order_status === 50 ? true : false,
              },
            ];
          }
          if (res.data.order_status === 60) {
            this.flowList = [
              {
                name: "待付款",
                time: res.data.create_time,
                isExistence: true,
              },
              {
                name: "已取消",
                time: res.data.expir_time,
                isExistence: true,
              },
            ];
          }
          this.info = res.data;
          this.goodsList.push({
            image: res.data.image,
            order_price: res.data.order_price,
            product_title: res.data.product_title,
            class: res.data.class,
            goods_total: res.data.goods_total,
            hierarchy_name: res.data.hierarchy_name,
            verification_time: res.data.verification_time,
            goods_name: res.data.goods_name,
            goods_number: res.data.goods_number,
          });
        }
      });
    },
    cancelVerifer() {
      this.visiable = false;
    },
    handleWriteOff(item) {
      checkVerificationCode({
        activity_type: 70,
        client_system_id: this.client_system_id,
        order_id: this.info.order_id,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "核销成功",
            type: "success",
          });
          this.cancelVerifer();
          this.getOrderInfo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  margin-bottom: 10px;
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.orderFlow {
  margin: 0 auto;
  width: 50%;
  display: flex;
  .flowIcon {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .line {
    margin-top: 10px;
    width: 180px;
    height: 1px;
  }
}
.flowTip {
  margin: 10px auto 0 auto;
  display: flex;
  width: 65%;
  font-size: 14px;
  .tipBox {
    width: 25%;
    text-align: center;
  }
}
.title {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .el-page-header__title {
  color: #565656;
}
/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}
</style>
