<template>
  <div>
    <div v-if="!isDetail">
      <div class="card" style="border-radius: 6px">
        <el-form :model="cond" label-width="100px" style="display: flex">
          <div>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <el-form-item label="订单号">
              <el-input
                placeholder="请输入订单号"
                style="width: 180px"
                v-model="cond.order_number"
              />
            </el-form-item>
            <el-form-item label="会员名称">
              <el-input
                placeholder="请输入会员名称"
                style="width: 180px"
                v-model="cond.user_name"
              />
            </el-form-item>
            <el-form-item label="会员手机">
              <el-input
                placeholder="请输入会员手机"
                style="width: 180px"
                v-model="cond.phone"
              />
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                placeholder="请输入商品名称"
                style="width: 180px"
                v-model="cond.product_title"
              />
            </el-form-item>
            <el-form-item label="商品分类">
              <el-select
                placeholder="请选择分类"
                v-model="cond.class_ids"
                style="width: 180px"
                multiple
                filterable
              >
                <template v-for="(item, index) in classList">
                  <el-option
                    :value="item.integral_store_product_class_id"
                    :label="item.product_class_name"
                    :key="index"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="兑换方式">
              <el-select
                placeholder="请选择兑换方式"
                style="width: 180px"
                v-model="cond.trading_method"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="积分" :value="1"></el-option>
                <el-option label="积分+人民币" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="下单时间"> </el-form-item>
            <el-date-picker
              v-model="time"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div style="margin-left: 20px; width: 20%">
            <el-button type="primary" style="height: 40px" @click="getOrderList"
              >搜索</el-button
            >
            <el-button style="height: 40px" @click="handleReset"
              >重置</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="content" style="margin-top: 20px; border: 1px solid #e8e8e8">
        <div class="tab">
          <template v-for="(item, index) in tabList">
            <div
              :key="index"
              class="tabs"
              :style="tab === item.value ? 'color:#1991FF' : ''"
              @click="handleTab(item)"
            >
              {{ item.value }}({{ item.num }})
              <div class="underLne" v-if="tab === item.value"></div>
            </div>
          </template>
        </div>
        <div class="table" style="border-top: 1px solid #e8e8e8">
          <el-table :data="list" stripe height="630">
            <el-table-column
              label="序号"
              width="80"
              align="center"
              type="index"
            ></el-table-column>
            <el-table-column
              label="订单号"
              align="center"
              prop="order_number"
              width="180"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              align="center"
              prop="product_title"
              width="180"
            >
              <template slot-scope="scope">
                {{ scope.row.product_title }}
              </template>
            </el-table-column>
            <el-table-column label="商品图片" align="center" prop="image">
              <template slot-scope="scope">
                <img
                  :src="scope.row.image || no_img"
                  style="width: 56px; height: 56px; margin: 5px 0 0 0"
                />
              </template>
            </el-table-column>
            <el-table-column label="商品分类" align="center" prop="class">
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in scope.row.class"
                  style="display: flex; flex-wrap: wrap"
                  :key="index"
                >
                  <div style="margin-right: 10px">{{ item }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="会员名称"
              align="center"
              prop="member_name"
            ></el-table-column>
            <el-table-column
              label="会员手机号"
              align="center"
              prop="member_phone"
              width="180"
            ></el-table-column>
            <el-table-column
              label="用户备注"
              align="center"
              prop="remark"
            ></el-table-column>
            <el-table-column
              label="支付时间"
              align="center"
              prop="pay_time"
              width="180"
            ></el-table-column>
            <el-table-column
              label="兑换方式"
              align="center"
              prop="trading_method"
              width="180"
            >
              <template slot-scope="scope">
                {{ scope.row.trading_method === 1 ? "积分" : "积分+人民币" }}
              </template>
            </el-table-column>
            <el-table-column
              label="购买数量"
              align="center"
              prop="goods_total"
            ></el-table-column>
            <el-table-column
              label="订单价格"
              align="center"
              prop="order_price"
              width="180"
            ></el-table-column>
            <el-table-column
              label="领取门店"
              align="center"
              prop="hierarchy_name"
              width="180"
            ></el-table-column>
            <el-table-column
              label="订单状态"
              align="center"
              prop="order_status"
            >
              <template slot-scope="scope">
                <div>
                  <span
                    v-if="orderStatus[scope.row.order_status] === '待领取'"
                    style="color: #67c23a"
                    >{{ orderStatus[scope.row.order_status] }}</span
                  >
                  <span
                    v-else-if="orderStatus[scope.row.order_status] === '已领取'"
                    style="color: #1991ff"
                    >{{ orderStatus[scope.row.order_status] }}</span
                  >
                  <span
                    v-else-if="orderStatus[scope.row.order_status] === '待付款'"
                    style="color: #e6a23c"
                    >{{ orderStatus[scope.row.order_status] }}</span
                  >
                  <span
                    v-else-if="orderStatus[scope.row.order_status] === '已过期'"
                    style="color: #f56c6c"
                    >{{ orderStatus[scope.row.order_status] }}</span
                  >
                  <span v-else style="color: #909399">{{
                    orderStatus[scope.row.order_status]
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          :page-sizes="[15, 30, 40, 50]"
          :page-size="15"
          :page-count="cond.page"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <OrderDetail
      v-if="isDetail"
      :client_system_id="client_system_id"
      :order_id="order_id"
      @hide="getOrderList"
    />
  </div>
</template>

<script>
import no_img from "@/assets/images/no_img.png";
import OrderDetail from "./OrderDetail.vue";
import {
  orderList,
  getGoodsClassAll,
  orderExport,
} from "@/api/marketing/shop/index.js";
export default {
  data() {
    return {
      isDetail: false,
      order_id: 0,
      no_img: no_img,
      cond: {
        page: 1,
        limit: 15,
      },
      tab: "全部",
      order_status: null,
      tabList: [
        { key: null, value: "全部", num: 0 },
        { key: 20, value: "待领取", num: 0 },
        { key: 50, value: "已领取", num: 0 },
        { key: 10, value: "待付款", num: 0 },
        { key: 60, value: "已关闭", num: 0 },
      ],
      classList: [],
      list: [],
      total: 0,
      orderStatus: {
        10: "待付款",
        20: "待领取",
        30: "已发货",
        40: "已收货",
        50: "已领取",
        60: "已关闭",
        80: "已过期",
      },
      time: [],
    };
  },
  props: {
    client_system_id: Number,
  },
  components: {
    OrderDetail,
  },
  created() {
    this.getOrderList();
    this.getGoodsClassAll();
  },
  methods: {
    /*
     *  导出
     */
    handleExport() {
      delete this.cond.limit;
      delete this.cond.page;
      orderExport({ ...this.cond, client_system_id: this.client_system_id })
        .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `商城订单导出.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: res.msg,
          });
        });
    },
    handleDetail(row) {
      this.isDetail = true;
      this.order_id = row.order_id;
    },
    handleTab(item) {
      this.cond.page = 1;
      this.order_status = item.key;
      this.tab = item.value;
      this.cond.order_status = item.key;
      this.getOrderList();
    },
    getGoodsClassAll() {
      getGoodsClassAll({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.classList = res.data;
          }
        }
      );
    },
    handleReset() {
      this.time = [];
      this.cond = {
        page: 1,
        limit: 15,
        order_status: this.orderStatus,
      };
      this.getOrderList();
    },
    handleSizeChange(val) {
      this.cond.limit = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getOrderList();
    },
    getOrderList() {
      this.isDetail = false;
      if (this.time.length !== 0) {
        this.cond.time_start = this.time[0] + " 00:00:00";
        this.cond.time_end = this.time[1] + " 23:59:59";
      }
      orderList({ ...this.cond, client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.list = res.data.list;
            this.total = res.data.total;
            this.tabList[0].num = res.data.num.all_num;
            this.tabList[1].num = res.data.num.unclaimed_num;
            this.tabList[2].num = res.data.num.done_num;
            this.tabList[3].num = res.data.num.payment_num;
            this.tabList[4].num = res.data.num.closed_num;
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  overflow: hidden;
}
/deep/.card .el-form-item {
  margin-bottom: 10px;
}
.tab {
  display: flex;
  width: 100%;
  height: 50px;
  line-height: 50px;
  .tabs {
    width: 8%;
    text-align: center;
    .underLne {
      margin: -5px auto 0 auto;
      width: 18px;
      height: 4px;
      border-radius: 2px;
      opacity: 1;
      background: rgba(25, 145, 255, 1);
    }
  }
  .tabs:hover {
    cursor: pointer;
  }
}
</style>
