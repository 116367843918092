<template>
  <div style="display: flex">
    <div class="navigation">
      <div class="title">
        <div class="span"></div>
        基本信息
      </div>
      <template v-for="(item, index) in navigationList">
        <div
          class="box"
          :key="index"
          @click="handleNavigation(item)"
          :style="tab === item ? 'background: rgba(25,145,255,0.1);' : null"
        >
          {{ item }}
        </div>
      </template>
    </div>
    <div style="margin-left: 15px; width: 89%">
      <Home v-if="tab === '首页'" :client_system_id="client_system_id" />
      <GoodsManage
        v-if="tab === '商品管理'"
        :client_system_id="client_system_id"
      />
      <GoodsClassify
        v-if="tab === '商品分类'"
        :client_system_id="client_system_id"
      />
      <GoodsSpecifications
        v-if="tab === '商品规格'"
        :client_system_id="client_system_id"
      />
      <Setup
        v-if="tab === '商城设置'"
        :type="2"
        :client_system_id="client_system_id"
      />
      <Order v-if="tab === '订单管理'" :client_system_id="client_system_id" />
    </div>
  </div>
</template>

<script>
import Home from "./Home.vue";
import GoodsManage from "./GoodsManage.vue";
import GoodsClassify from "./GoodsClassify.vue";
import GoodsSpecifications from "./GoodsSpecifications.vue";
import Setup from "./Setup.vue";
import Order from "./OrderList.vue";
export default {
  data() {
    return {
      tab: "首页",
      navigationList: ["首页", "商品管理", "商品分类", "订单管理", "商城设置"],
    };
  },
  props: {
    client_system_id: Number,
  },
  created() {
    console.log(this.client_system_id, "client_system_id");
  },
  components: {
    Home,
    GoodsManage,
    GoodsClassify,
    GoodsSpecifications,
    Setup,
    Order,
  },
  methods: {
    handleNavigation(item) {
      this.tab = item;
    },
  },
};
</script>

<style lang="less" scoped>
.navigation {
  font-size: 16px;
  width: 10%;
  height: 90vh;
  background: white;
  border: 1px solid #e8e8e8;
  .box {
    padding-left: 31px;
    line-height: 56px;
  }
  .box:hover {
    cursor: pointer;
  }
}
.title {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  line-height: 56px;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
</style>  