<template>
  <div>
    <div class="card" style="display: flex; border-radius: 5px">
      <el-button
        style="width: 108px; height: 40px"
        class="el-icon-plus"
        type="primary"
        size="medium"
        @click="handleAdd"
        >添加规格</el-button
      >
      <el-form :model="cond" label-width="120px">
        <el-form-item label="规格名称">
          <el-input
            placeholder="请输入规格名称"
            style="width: 240px"
            v-model="cond.attribute_name"
          />
        </el-form-item>
      </el-form>
      <div style="display: flex; margin-left: 20px">
        <el-button
          type="primary"
          @click="getGoodsAttributeList"
          style="height: 40px"
          >搜索</el-button
        >
        <el-button @click="resetForm" style="height: 40px">重置</el-button>
      </div>
    </div>
    <div
      class="table"
      style="
        margin-top: 15px;
        border-top: 1px solid #e8e8e8;
        border-radius: 5px;
      "
    >
      <el-table :data="list" stripe max-height="700" height="700">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="80px"
        ></el-table-column>
        <el-table-column
          prop="attribute_name"
          label="规格"
          align="center"
          width="300"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.attribute_name"
              v-if="scope.row.isAdd"
              style="width: 180px; margin: 5px 0"
              placeholder="请输入规格"
              maxlength="10"
              show-word-limit
            />
            <span v-if="!scope.row.isAdd">{{ scope.row.attribute_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="value" label="规格参数" align="center">
          <template slot-scope="scope"
            ><div style="display: flex; flex-wrap: wrap; margin-top: 8px">
              <div v-for="item in scope.row.value" :key="item.attribute_id">
                <div class="box" v-if="item.attribute_value !== ''">
                  <div
                    class="close"
                    v-if="scope.row.isEdit || scope.row.isAdd"
                    @click="handleClose(scope.row, item)"
                  >
                    <img src="@/assets/images/activity/close.svg" />
                  </div>
                  {{ item.attribute_value }}
                </div>
              </div>
              <div v-for="(item, index) in scope.row.otherValue" :key="index">
                <div
                  style="
                    margin: 0px 17px 5px 0;
                    width: 180px;
                    position: relative;
                  "
                >
                  <el-input
                    v-model="item.attribute_value"
                    placeholder="请输入"
                    maxlength="8"
                    show-word-limit
                  />
                  <div
                    class="close"
                    style="z-index: 999"
                    v-if="scope.row.isEdit || scope.row.isAdd"
                    @click="handleClose(scope.row, item, index, scope.$index)"
                  >
                    <img src="@/assets/images/activity/close.svg" />
                  </div>
                </div>
              </div>

              <div
                style="width: 28px; height: 28px; margin: 0px 0 0 0px"
                v-if="scope.row.isEdit || scope.row.isAdd"
                @click="handleAddAttrValue(scope.row, scope.$index)"
              >
                <img
                  src="@/assets/images/activity/icon_jia.png"
                  style="width: 28px; height: 28px"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="!scope.row.isEdit && !scope.row.isAdd"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleSubmit(scope.row)"
              v-if="scope.row.isEdit || scope.row.isAdd"
              >保存</el-button
            >
            <el-button type="text" @click="handleDelGoods(scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="cond.page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        :page-sizes="[15, 30, 40, 50]"
        :page-size="15"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getGoodsAttributeList,
  delGoodsAttributeInfo,
  createGoodsAttributeInfo,
} from "@/api/marketing/shop/index.js";
export default {
  data() {
    return {
      cond: {
        page: 1,
        limit: 15,
      },
      list: [],
      total: 0,
    };
  },
  created() {
    this.getGoodsAttributeList();
  },
  props: {
    client_system_id: Number,
  },
  methods: {
    resetForm() {
      this.cond = {
        page: 1,
        limit: 15,
      };
      this.getGoodsAttributeList();
    },
    handleAdd() {
      this.list.unshift({
        isAdd: true,
        otherValue: [{ attribute_value: "" }],
        attribute_name: "",
      });
      this.$set(this.list);
    },
    getGoodsAttributeList() {
      getGoodsAttributeList({
        ...this.cond,
        client_system_id: this.client_system_id,
      }).then((res) => {
        if (res.code === 1) {
          res.data.list = res.data.list.map((item) => {
            item.isEdit = false;
            item.otherValue = [];
            return item;
          });
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.cond.limit = val;
      this.getGoodsAttributeList();
    },
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getGoodsAttributeList();
    },
    handleAddAttrValue(row, i) {
      this.list = this.list.map((item, index) => {
        if (index === i) {
          item.otherValue.push({
            attribute_value: "",
          });
        }
        return item;
      });
    },
    handleClose(row, data, index, rowIndex) {
      if (data.attribute_id) {
        delGoodsAttributeInfo({
          client_system_id: this.client_system_id,
          attribute_ids: [data.attribute_id],
        }).then((res) => {
          if (res.code === 1) {
            this.$confirm("此操作将删除该规格参数, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.list = this.list.map((item) => {
                if (item.attribute_name === row.attribute_name) {
                  item.value = item.value.filter(
                    (i) => i.attribute_value !== data.attribute_value
                  );
                }
                return item;
              });
            });
          }
        });
      } else {
        this.list = this.list.map((item, itemIndex) => {
          if (itemIndex === rowIndex) {
            item.otherValue = item.otherValue.filter((v, i) => i !== index);
          }
          return item;
        });
      }

      this.$set(this.list);
    },
    handleEdit(row) {
      this.list = this.list.map((item) => {
        if (item.attribute_name === row.attribute_name) {
          item.isEdit = true;
        }
        return item;
      });
      this.$set(this.list);
    },
    /*
     *  保存
     */
    handleSubmit(row) {
      if (row.otherValue.length === 0) {
        this.getGoodsAttributeList();
      } else {
        createGoodsAttributeInfo({
          client_system_id: this.client_system_id,
          attribute_name: row.attribute_name,
          attribute_values: row.otherValue.map((item) => {
            return item.attribute_value;
          }),
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getGoodsAttributeList();
          }
        });
      }
    },
    /*
     *  删除
     */
    handleDelGoods(row, i) {
      if (row.isAdd) {
        this.list = this.list.filter(
          (item, index) => index !== i
        );
      } else {
        this.$confirm("此操作将删除该规格参数, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delGoodsAttributeInfo({
            client_system_id: this.client_system_id,
            attribute_ids: row.value.map((item) => {
              return item.attribute_id;
            }),
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getGoodsAttributeList();
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .card .el-form-item {
  margin-bottom: 0;
}
.box {
  position: relative;
  margin: 0px 17px 8px 0;
  padding: 5px 10px;
  border: 1px solid #909399;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
}
.close {
  position: absolute;
  top: -8px;
  right: -12px;
  width: 18px;
  height: 18px;
}
</style>