<template>
  <div>
    <div class="card" style="display: flex; border-radius: 5px">
      <el-button
        style="width: 108px; height: 40px"
        class="el-icon-plus"
        type="primary"
        size="medium"
        @click="handleAdd"
        >添加分类</el-button
      >
      <el-form :model="cond" label-width="120px">
        <el-form-item label="分类名称">
          <el-input
            placeholder="请输入分类名称"
            style="width: 240px"
            v-model="cond.product_class_name"
          />
        </el-form-item>
      </el-form>
      <div style="display: flex; margin-left: 20px">
        <el-button
          type="primary"
          @click="getGoodsClassList"
          style="height: 40px"
          >搜索</el-button
        >
        <el-button @click="resetForm" style="height: 40px">重置</el-button>
      </div>
    </div>
    <div
      class="table"
      style="
        margin-top: 15px;
        border-top: 1px solid #e8e8e8;
        border-radius: 5px;
      "
    >
      <el-table :data="list" stripe max-height="700" height="700">
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="80px"
        ></el-table-column>
        <el-table-column
          prop="product_class_name"
          label="商品分类"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="product_count"
          label="关联商品数量"
          align="center"
        ></el-table-column>
        <el-table-column prop="is_display" label="首页展示" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_display"
              :active-value="1"
              :inactive-value="0"
              @change="handleUpdateStoreStatus(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.product_count === 0"
              @click="handleDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="cond.page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        :page-sizes="[15, 30, 40, 50]"
        :page-size="15"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog
      title="创建分类"
      :visible.sync="visiable"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
      :before-close="cancelDialog"
    >
      <el-form :model="info" label-width="120px" :rules="rule" ref="info">
        <el-form-item label="分类名称" prop="product_class_name">
          <el-input
            placeholder="请输入分类名称"
            v-model="info.product_class_name"
            style="width: 60%"
            maxlength="6"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            placeholder="请输入排序"
            v-model="info.sort"
            style="width: 60%"
            maxlength="4"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="展示设置">
          <el-checkbox
            v-model="info.is_display"
            :true-label="1"
            :false-label="0"
            >首页展示</el-checkbox
          >
          <div style="color::#909399;margin-top:-5px">
            温馨提示：勾选后，该分类项在首页中展示，展示上限20个
          </div>
        </el-form-item>
        <div style="text-align: center">
          <el-button type="primary" @click="createGoodsClass()">保存</el-button>
          <el-button @click="cancelDialog">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsClassList,
  addGoodsClass,
  updateGoodsClass,
  delGoodsClass,
} from "@/api/marketing/shop/index.js";
export default {
  data() {
    return {
      visiable: false,
      cond: {
        page: 1,
        limit: 15,
      },
      list: [],
      total: 0,
      info: {
        name: "",
      },
      rule: {
        product_class_name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    client_system_id: Number,
  },
  created() {
    console.log(this.client_system_id);
    this.getGoodsClassList();
  },
  methods: {
    handleDel(row) {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delGoodsClass({
          class_ids: [row.integral_store_product_class_id],
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getGoodsClassList();
          }
        });
      });
    },
    handleAdd() {
      this.visiable = true;
    },
    createGoodsClass() {
      this.$refs.info.validate((valid) => {
        if (valid) {
          if (!this.info.integral_store_product_class_id) {
            addGoodsClass({
              ...this.info,
              client_system_id: this.client_system_id,
            }).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "新增成功！",
                  type: "success",
                });
                this.cancelDialog();
                this.getGoodsClassList();
              }
            });
          } else {
            updateGoodsClass({
              ...this.info,
              client_system_id: this.client_system_id,
            }).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "更新成功！",
                  type: "success",
                });
                this.cancelDialog();
                this.getGoodsClassList();
              }
            });
          }
        }
      });
    },
    cancelDialog() {
      this.info = {
        name: "",
      };
      this.visiable = false;
    },
    handleUpdateStoreStatus(row) {
      updateGoodsClass({
        ...row,
        client_system_id: this.client_system_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "更新成功！",
              type: "success",
            });
            this.getGoodsClassList();
          }
        })
        .catch((res) => {
         this.list = this.list.map(item => {
           return item
         })
        });
    },
    resetForm() {
      this.cond = {
        page: 1,
        limit: 15,
        client_system_id: this.client_system_id,
      };
      this.getGoodsClassList();
    },
    getGoodsClassList() {
      getGoodsClassList({
        ...this.cond,
        client_system_id: this.client_system_id,
      }).then((res) => {
        if (res.code === 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.cond.limit = val;
      this.getGoodsClassList();
    },
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getGoodsClassList();
    },
    handleEdit(row) {
      this.info = row;
      this.info.name = row.product_class_name;
      this.visiable = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .card .el-form-item {
  margin-bottom: 0;
}
</style>