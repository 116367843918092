<template>
  <div class="content" style="padding: 28px 0 15px 0">
    <div class="title">
      <div class="span"></div>
      选择会员体系
    </div>
    <div class="tip">
      温馨提示：选择体系后才可以创建积分商城，如果没有创建并绑定会员体系，需完成会员体系的创建与绑定
    </div>
    <div class="systemList">
      <template v-for="(item, index) in list">
        <div
          class="systemBox"
          :key="index"
          :style="
            !item.wechat_name || item.wechat_name === ''
              ? 'background: rgba(0,0,0,0.05);color:#909399'
              : ''
          "
        >
          <div class="systemTitle">{{ item.title }}</div>
          <div style="display: flex; margin-top: 30px">
            <div style="font-size: 16px; color: #909399; width: 130px">
              公众号
            </div>
            <div>{{ item.wechat_name || "未绑定公众号" }}</div>
          </div>
          <div style="display: flex; margin-top: 20px">
            <div style="font-size: 16px; color: #909399; width: 130px">
              门店
            </div>
            <div style="width: 208px;">
               <el-tooltip class="item" effect="dark"  placement="bottom">
                <span class="hierarchy_name">{{ item.hierarchy_name.join(",")}}</span>
                <div style="width: 200px;" slot="content">{{ item.hierarchy_name.join(",")}}</div>
              </el-tooltip>
            </div>
          </div>
          <div style="display: flex; margin-top: 20px">
            <div style="font-size: 16px; color: #909399; width: 130px">
              积分商城
            </div>
            <div v-if="item.integral_store_name" style="color: #1991ff">
              已创建
            </div>
            <div v-else>未创建</div>
          </div>
          <div
            class="action"
            v-if="!item.wechat_name || item.wechat_name === ''"
            @click="handleMemberSystemBind"
          >
            去绑定公众号
          </div>
          <div
            class="action"
            v-else-if="!item.integral_store_name"
            @click="handleShopInfo(item, 1)"
          >
            创建积分商城
          </div>
          <div class="action" v-else-if="item.hierarchy_name.length==0&&item.wechat_name||item.hierarchy_name.length==0&&item.wechat_name === ''" @click="handleMemberSystemBind">
            去添加门店
          </div>
          <div class="action" v-else @click="handleShopInfo(item, 2)">
             进入积分商城
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getClientSystemList } from "@/api/marketing/shop/index.js";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getClientSystemList();
  },
  methods: {
    getClientSystemList() {
      getClientSystemList().then((res) => {
        if (res.code === 1) {
          this.list = res.data;
        }
      });
    },
    handleMemberSystemBind() {
      this.$router.push({
        name: "memberSystemBindList",
      });
    },
    handleShopInfo(row, type) {
      this.$emit("hide", { ...row, type });
    },
  },
};
</script>

<style scoped lang="less">
.hierarchy_name{
  cursor:default;
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;				//溢出内容隐藏
	text-overflow: ellipsis;		//文本溢出部分用省略号表示
	display: -webkit-box;			//特别显示模式
	-webkit-line-clamp: 2;			//行数
	line-clamp: 2;
	-webkit-box-orient: vertical;	//盒子中内容竖直排列

}
.title {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.tip {
  margin-left: 30px;
  color: #909399;
}
.systemList {
  width: 97%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  .systemBox {
    margin-right: 10px;
    margin-top: 30px;
    padding-top: 20px;
    padding-left: 20px;
    width: 404px;
    height: 268px;
    font-size: 16px;
    border: 1px solid #f0f1f5;
    .systemTitle {
      font-weight: 600;
    }
  }
  .action {
    margin: 28px auto 0 auto;
    width: 140px;
    height: 40px;
    border-radius: 6px;
    text-align: center;
    color: white;
    line-height: 40px;
    background: rgba(25, 145, 255, 1);
  }
  .action:hover {
    cursor: pointer;
  }
}
</style>
