<template>
  <div class="content" style="padding: 25px 0">
    <el-form
      label-width="120px"
      style="margin-left: 30px"
      :model="shopInfo"
      :rules="shopRule"
      ref="shopInfo"
    >
      <div class="title">
        <div class="span"></div>
        基本信息
      </div>
      <el-form-item label="绑定体系" style="margin-top: 15px">{{
        shopInfo.client_system_title || title
      }}</el-form-item>
      <el-form-item label="商城名称" prop="integral_store_name">
        <el-input
          placeholder="请输入商城名称"
          style="width: 300px"
          v-model="shopInfo.integral_store_name"
        />
        <div style="color: #909399">提示：积分商城名称2-10个字以内</div>
      </el-form-item>
      <div class="title">
        <div class="span"></div>
        轮播广告图
      </div>
      <div
        style="margin-top: 20px; display: flex; flex-wrap: wrap"
        v-if="bannerList.length !== 0"
      >
        <template v-for="(item, index) in bannerList">
          <div
            style="margin-right: 20px; position: relative; margin-bottom: 10px"
            :key="index"
          >
            <img
              src="@/assets/images/activity/close.svg"
              style="
                position: absolute;
                right: -10px;
                top: -10px;
                object-fit: cover;
              "
              @click="delBanner(item)"
            />
            <img
              :src="item.image"
              style="width: 270px; height: 108px; object-fit: cover"
            />
          </div>
        </template>
      </div>
      <el-button
        class="el-icon-plus"
        type="primary"
        style="margin-top: 15px"
        size="small"
        @click="handleAddBanner"
        >添加轮播图</el-button
      >

      <div style="margin: 10px 0 30px 0; color: #909399">
        温馨提示：最多只能添加6张轮播图
      </div>
      <div class="title">
        <div class="span"></div>
        门店设置
      </div>
      <div style="margin: 30px 0 50px 0; width: 50%; border: 1px solid #e8e8e8">
        <el-table :data="hierarcyhList" stripe>
          <el-table-column
            label="序号"
            type="index"
            width="80x"
            align="center"
          ></el-table-column>
          <el-table-column
            label="门店"
            align="center"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="电话"
            align="center"
            prop="tel"
          ></el-table-column>
          <el-table-column
            label="门店地址"
            align="center"
            prop="addr"
          ></el-table-column>
          <el-table-column label="启用积分商城" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleUpdateStoreStatus(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="title">
        <div class="span"></div>
        基础设置
      </div>
      <el-form-item
        label="商品销量"
        style="margin-top: 20px"
        prop="is_product_sales"
      >
        <el-radio-group v-model="shopInfo.is_product_sales">
          <el-radio :label="0">隐藏</el-radio>
          <el-radio :label="1">展示</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="订单有效期"
        style="color: #606266"
        prop="validity_of_order"
      >
        <el-input
          style="margin-right: 10px; width: 90px"
          v-model="shopInfo.validity_of_order"
          placeholder="请输入"
        />分钟有效
      </el-form-item>
      <el-form-item
        label="核销有效期"
        style="color: #606266"
        prop="confirm_of_order"
      >
        请输入有效期<el-input
          style="margin: 0 10px; width: 90px"
          placeholder="请输入"
          v-model="shopInfo.confirm_of_order"
        />天（核销逾期自动关闭订单）
      </el-form-item>
      <el-form-item
        label="核销密码"
        style="color: #606266"
        prop="confirm_password"
      >
        <el-input
          v-model="shopInfo.confirm_password"
          placeholder="请输入"
          style="margin-right: 10px; width: 90px"
        />（设置密码密码后，用户核销界面输入密码即可进行核销）
      </el-form-item>
      <div style="text-align: center">
        <el-button v-if="type === 1" @click="goBack">返回</el-button>
        <el-button type="primary" @click="submitShopInfo">保存</el-button>
      </div>
    </el-form>
    <el-dialog
      title="轮播广告图"
      :visible.sync="bannerVisiable"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="30%"
      :before-close="cancelBannerDialog"
    >
      <div>
        <div class="bannerImg">
          <div v-if="!bannerInfo.image" style="">轮播广告图</div>
          <img
            v-if="bannerInfo.image"
            :src="bannerInfo.image"
            style="width: 100%; height: 100%; border-radius: 6px"
          />
        </div>
        <el-upload
          class="upload"
          action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
          :data="{
            directory: 'AGENT_INFO',
          }"
          :headers="{
            authorization: token,
          }"
          :show-file-list="false"
          :on-success="handleBannerImageSuccess"
        >
          <el-button type="primary" size="mini" class="el-icon-plus"
            >上传图片</el-button
          ></el-upload
        >
        <div style="margin: 8px 0 0 28px; color: #909399">
          上传1M以内的JPG/PNG格式图片，建议尺寸为750*300px
        </div>
        <div
          style="
            display: flex;
            margin: 20px 0 0 28px;
            color: #909399;
            line-height: 40px;
          "
        >
          <div style="margin-right: 56px">链接地址</div>
          <el-input
            placeholder="请输入链接地址"
            v-model="bannerInfo.url"
            style="width: 280px"
          />
        </div>
        <div style="text-align: center; margin-top: 10px">
          <el-button @click="cancelBannerDialog">取消</el-button>
          <el-button type="primary" @click="submitBanner">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getHierarcyhList,
  updateHierarchy,
  getShopSetInfo,
  createShopConfig,
  updateShopConfig,
  getBannerList,
  saveBanner,
} from "@/api/marketing/shop/index.js";
import Cookies from "js-cookie";
import storage from "good-storage";
export default {
  data() {
    return {
      bannerVisiable: false,
      storeList: [],
      shopInfo: {
        is_product_sales: 1,
        validity_of_order: 15,
        confirm_password: 8888,
      },
      token: "",
      bannerInfo: {
        url: "",
        image: null,
      },
      bannerList: [],
      hierarcyhList: [],
      shopRule: {
        integral_store_name: [
          {
            required: true,
            message: "请输入商城名称",
            trigger: "blur",
          },
        ],
        validity_of_order: [
          {
            required: true,
            message: "请输入订单有效期",
            trigger: "blur",
          },
        ],
        is_product_sales: [
          {
            required: true,
            message: "请选择商品销量",
            trigger: "change",
          },
        ],
        confirm_of_order: [
          {
            required: true,
            message: "请输入核销有效期",
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: "请输入核销密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    type: Number,
    client_system_id: Number,
    title: String,
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getHierarcyhList();
    if (this.type === 2) {
      this.getShopConfigInfo();
      this.getBannerList();
    }
  },
  methods: {
    getBannerList() {
      getBannerList({ client_system_id: this.client_system_id }).then((res) => {
        if (res.code === 1) {
          this.bannerList = res.data;
        }
      });
    },
    handleAddBanner() {
      if (this.bannerList.length < 6) {
        this.bannerVisiable = true;
      } else {
        this.$message({
          message: "最多上传6张轮播广告图",
          type: "error",
        });
      }
    },
    delBanner(row) {
      this.bannerList = this.bannerList.filter(
        (item) => item.image !== row.image
      );
    },
    cancelBannerDialog() {
      this.bannerInfo = {
        url: "",
        image: null,
      };
      this.bannerVisiable = false;
    },
    handleBannerImageSuccess(res, file) {
      this.bannerInfo.image = res.data;
    },
    submitBanner() {
      if (!this.bannerInfo.image) {
        this.$message({
          message: "请上传轮播广告图",
          type: "error",
        });
      } else {
        this.bannerList.push(this.bannerInfo);
        this.cancelBannerDialog();
      }
    },
    getHierarcyhList() {
      getHierarcyhList({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.hierarcyhList = res.data;
          }
        }
      );
    },
    handleUpdateStoreStatus(row) {
      updateHierarchy({
        client_system_id: this.client_system_id,
        hierarchy_id: row.hierarchy_id,
        status: row.status,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getHierarcyhList();
        }
      });
    },
    getShopConfigInfo() {
      getShopSetInfo({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.shopInfo = res.data;
          }
        }
      );
    },
    goBack() {
      this.$emit("hide");
    },
    submitShopInfo() {
      if (this.type === 1) {
        this.$refs.shopInfo.validate((valid) => {
          if (valid) {
            if (this.bannerList.length !== 0) {
              saveBanner({
                client_system_id: this.client_system_id,
                images: JSON.stringify(this.bannerList),
              }).then((res) => {
                if (res.code === 1) {
                  this.getBannerList();
                }
              });
            }

            createShopConfig({
              client_system_id: this.client_system_id,
              ...this.shopInfo,
            }).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "创建成功",
                  type: "success",
                });
                this.$emit("hide");
              }
            });
          }
        });
      } else {
        saveBanner({
          client_system_id: this.client_system_id,
          images: JSON.stringify(this.bannerList),
        }).then((res) => {
          if (res.code === 1) {
            this.getBannerList();
          }
        });
        updateShopConfig({
          client_system_id: this.client_system_id,
          ...this.shopInfo,
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getShopConfigInfo();
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.title {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .el-form-item {
  margin-bottom: 25px !important;
}
.bannerImg {
  margin: 0 auto;
  width: 90%;
  height: 285.6px;
  border-radius: 6px;
  background: rgba(240, 241, 245, 1);
  font-size: 20px;
  text-align: center;
  line-height: 285.6px;
  font-size: 20px;
}
.upload {
  margin: 10px 0 0 20px;
  width: 112px;
  height: 36px;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  line-height: 36px;
  color: white;
}
</style>