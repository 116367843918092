import { post, get } from '@/utils/request';

// 积分商城会员体系列表
const getClientSystemList = (data) => get({
    url: "/store/IntegralStore.ClientSystemList/list",
    data: data
})

// 商品分类列表
const getGoodsClassList = (data) => get({
    url: "/store/IntegralStore.ProductClass/list",
    params: data
})

// 商品分类新增
const addGoodsClass = (data) => post({
    url: "/store/IntegralStore.ProductClass/save",
    data: data
})

// 商品分类更新
const updateGoodsClass = (data) => post({
    url: "/store/IntegralStore.ProductClass/update",
    data: data
})

// 商品分类删除
const delGoodsClass = (data) => post({
    url: "/store/IntegralStore.ProductClass/delete",
    data: data
})

// 商品分类所有数据
const getGoodsClassAll = (data) => get({
    url: "/store/IntegralStore.ProductClass/listAll",
    params: data
})

// 商品规格列表
const getGoodsAttributeList = (data) => get({
    url: "/store/IntegralStore.Attribute/list",
    params: data
})

// 商品规格更新排序值
const updateGoodsAttributeInfo = (data) => post({
    url: "/store/IntegralStore.Attribute/ChangeOrder",
    data: data
})

// 商品规格新增
const createGoodsAttributeInfo = (data) => post({
    url: "/store/IntegralStore.Attribute/save",
    data: data
})

// 商品规格删除
const delGoodsAttributeInfo = (data) => post({
    url: "/store/IntegralStore.Attribute/delete",
    data: data
})

// 门店列表
const getHierarcyhList = (data) => get({
    url: "/store/IntegralStore.Hierarchy/list",
    params: data
})

// 门店状态设置
const updateHierarchy = (data) => post({
    url: "/store/IntegralStore.Hierarchy/update",
    data: data
})

// 商城创建
const createShopConfig = (data) => post({
    url: "/store/IntegralStore.Config/create",
    data: data,
})

// 商城信息更新
const updateShopConfig = (data) => post({
    url: "/store/IntegralStore.Config/update",
    data: data,
})

// 商城信息查看
const getShopConfigInfo = (data) => get({
    url: "/store/IntegralStore.Config/see",
    params: data
})

const getShopSetInfo = (data) => get({
    url: "/store/IntegralStore.Config/index",
    params: data
})

//  banner保存创建
const saveBanner = (data) => post({
    url: "/store/IntegralStore.Banner/save",
    data: data
})

// banner列表
const getBannerList = (data) => get({
    url: "/store/IntegralStore.Banner/list",
    params: data
})

// 商品列表
const getGoodsList = (data) => get({
    url: "/store/IntegralStore.Product/list",
    params: data
})

// 创建商品
const saveGoodsInfo = (data) => post({
    url: "/store/IntegralStore.Product/save",
    data: data
})

// 更新商品
const updateGoodsInfo = (data) => post({
    url: "/store/IntegralStore.Product/update",
    data: data
})

// 删除商品
const delGoodsInfo = (data) => post({
    url: "/store/IntegralStore.Product/delete",
    data: data
})

// 商品详情
const getGoodsInfo = (data) => get({
    url: "/store/IntegralStore.Product/read",
    params: data
})

// 商品批量上下架
const operatingGoodsInfo = (data) => post({
    url: "/store/IntegralStore.Product/operating",
    data: data
})

// 设置商品为推荐
const updateRecommend = (data) => post({
    url: "/store/IntegralStore.Product/updateRecommend",
    data: data
})

// 订单列表
const orderList = (data) => get({
    url: "/store/IntegralStore.Order/list",
    params: data
})

// 订单详情
const orderDetails = (data) => get({
    url: "/store/IntegralStore.Order/details",
    params: data
})
// 订单导出
const orderExport = (data) => post({
    url: "/store/IntegralStore.Order/export",
    responseType: 'blob',
    data: data
})

// 核销确认
const checkVerificationCode = (data) => post({
    url: "/store/order.Order/checkVerificationCode",
    data: data
})

// 商城开关
const updateStatusConfig = (data) => post({
    url: "/store/IntegralStore.Config/updateStatus",
    data: data
})

export {
    getClientSystemList,
    getGoodsClassList,
    addGoodsClass,
    updateGoodsClass,
    getGoodsClassAll,
    delGoodsClass,
    getGoodsAttributeList,
    updateGoodsAttributeInfo,
    createGoodsAttributeInfo,
    delGoodsAttributeInfo,
    getHierarcyhList,
    updateHierarchy,
    createShopConfig,
    updateShopConfig,
    getShopConfigInfo,
    getShopSetInfo,
    saveBanner,
    getBannerList,
    getGoodsList,
    saveGoodsInfo,
    updateGoodsInfo,
    delGoodsInfo,
    getGoodsInfo,
    operatingGoodsInfo,
    updateRecommend,
    orderList,
    orderDetails,
    orderExport,
    updateStatusConfig,
    checkVerificationCode
}