<template>
  <div>
    <div v-if="!add && !detail">
      <div class="card" style="border-radius: 6px">
        <div>
          <el-form :model="cond" label-width="100px" style="display: flex">
            <div style="display: flex">
              <el-button
                type="primary"
                class="el-icon-plus"
                style="margin-right: 10px"
                @click="handleAddGoods"
                >创建商品</el-button
              >
              <div style="line-height: 40px" v-if="tab !== '全部'">
                <el-dropdown @command="handleActionType">
                  <el-button type="primary">
                    批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="1" v-if="tab === '待上架'"
                      >上架</el-dropdown-item
                    >
                    <el-dropdown-item :command="2">删除</el-dropdown-item>
                    <el-dropdown-item :command="3" v-if="tab === '已上架'"
                      >设为推荐商品</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="5"
                      v-if="
                        tab === '已上架' || tab === '已售罄' || tab === '全部'
                      "
                      >下架</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <el-form-item label="商品名称">
              <el-input
                placeholder="请输入商品名称"
                style="width: 180px"
                v-model="cond.product_title"
              />
            </el-form-item>

            <el-form-item label="分类">
              <el-select
                placeholder="请选择分类"
                v-model="cond.class_ids"
                style="width: 180px"
                multiple
                filterable
              >
                <template v-for="(item, index) in classList">
                  <el-option
                    :value="item.integral_store_product_class_id"
                    :label="item.product_class_name"
                    :key="index"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select
                placeholder="请选择商品类型"
                style="width: 180px"
                v-model="cond.type"
              >
                <el-option label="自定义商品" :value="1"></el-option>
                <el-option label="在库商品" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推荐位">
              <el-select
                placeholder="请选择推荐位"
                style="width: 180px"
                v-model="cond.is_recommend"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="推荐商品" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <div style="margin-left: 20px">
              <el-button type="primary" style="height: 40px" @click="getList"
                >搜索</el-button
              >
              <el-button style="height: 40px" @click="handleReset"
                >重置</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <div class="content" style="margin-top: 20px; border: 1px solid #e8e8e8">
        <div class="tab">
          <template v-for="(item, index) in tabList">
            <div
              :key="index"
              class="tabs"
              :style="tab === item.value ? 'color:#1991FF' : ''"
              @click="handleTab(item)"
            >
              {{ item.value }}({{ item.num }})
              <div class="underLne" v-if="tab === item.value"></div>
            </div>
          </template>
        </div>
        <div class="table" style="border-top: 1px solid #e8e8e8">
          <el-table
            :data="list"
            stripe
            height="680"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="序号"
              width="80"
              align="center"
              type="index"
            ></el-table-column>
            <el-table-column label="商品信息" align="left" width="200">
              <template slot-scope="scope">
                <div style="display: flex">
                  <div class="goodsImage">
                    <img :src="scope.row.images || no_img" />
                  </div>
                  <div>
                    <div
                      style="
                        margin: 10px 0 0 9px;
                        width: 160px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      "
                    >
                      {{ scope.row.product_title }}
                    </div>
                    <div
                      style="
                        display: flex;
                        margin: 5px 0 0 9px;
                        color: #909399;
                        font-size: 12px;
                      "
                    >
                      <template v-for="(item, index) in scope.row.class">
                        <div :key="index">{{ item.product_class_name }}</div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="商品价格"
              align="center"
              prop="trading_method"
            >
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.sku.length !== 0">
                    {{ scope.row.sku[0].integral_price }}积分<span
                      v-if="scope.row.trading_method === 2"
                      >+{{ scope.row.sku[0].money_price }}元</span
                    >
                  </div>
                  <div style="font-size: 12px; color: #909399">
                    {{
                      scope.row.trading_method === 1
                        ? "（积分）"
                        : "（积分 + 人民币）"
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="商品类型" align="center" prop="type">
              <template slot-scope="scope">
                {{ scope.row.type === 1 ? "自定义商品" : "在库" }}
              </template>
            </el-table-column>
            <el-table-column label="商品状态" align="center" prop="status">
              <template slot-scope="scope">
                <div>
                  <span
                    v-if="status[scope.row.status] === '待上架'"
                    style="color: #67c23a"
                    >{{ status[scope.row.status] }}</span
                  >
                  <span
                    v-else-if="status[scope.row.status] === '已上架'"
                    style="color: #1991ff"
                    >{{ status[scope.row.status] }}</span
                  >
                  <span
                    v-else-if="status[scope.row.status] === '已售罄'"
                    style="color: #f56c6c"
                    >{{ status[scope.row.status] }}</span
                  >
                  <span v-else>{{ status[scope.row.status] }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="剩余数量"
              align="center"
              prop="product_hierarchy_num"
              width="220"
            >
              <template slot-scope="scope">
                <div
                  style="margin: 5px 0"
                  v-for="(item, index) in scope.row.product_hierarchy_num"
                  :key="index"
                >
                  <div style="text-align: left; font-size: 14px; display: flex">
                    <div style="width: 70%">{{ item.name }}：</div>
                    <div
                      :style="item.num === 0 ? 'color:#F56C6C' : ''"
                      style="width: 30%"
                    >
                      {{ item.num }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="已兑换"
              align="center"
              prop="exchange"
            ></el-table-column>
            <el-table-column
              label="已领取"
              align="center"
              prop="receive"
            ></el-table-column>
            <el-table-column
              label="设为推荐商品"
              align="center"
              prop="is_recommend"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_recommend"
                  :active-value="1"
                  :inactive-value="0"
                  @change="updateRecommend(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="220">
              <template slot-scope="scope">
                <el-button type="text" @click="handleEdit(scope.row)">{{
                  scope.row.status === 2 ? "查看" : "查看/编辑"
                }}</el-button>
                <el-button
                  type="text"
                  @click="handlePut(scope.row)"
                  v-if="
                    scope.row.status === 1 ||
                    scope.row.status === 3 ||
                    scope.row.status === 5
                  "
                  >上架</el-button
                >
                <el-button
                  type="text"
                  @click="handleDown(scope.row)"
                  v-if="scope.row.status === 2"
                  >下架</el-button
                >
                <el-button
                  type="text"
                  @click="
                    copylogistics(
                      scope.row.product_url +
                        'shop/goodsDetail?product_id=' +
                        scope.row.integral_store_product_id +
                        '&client_system_id=' +
                        client_system_id
                    )
                  "
                  data-clipboard-text="复制"
                  class="copy"
                  v-if="scope.row.status === 2"
                  >复制商品链接</el-button
                >
                <el-button
                  type="text"
                  @click="handleDel(scope.row)"
                  v-if="scope.row.status !== 2 && scope.row.status !== 5"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          :page-sizes="[15, 30, 40, 50]"
          :page-size="15"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <GoodsCreate
      v-if="add"
      @hide="getList"
      :product_id="product_id"
      :client_system_id="client_system_id"
      :status="goodsStatus"
      :check="check"
    />
    <GoodsDetail
      v-if="detail"
      @hide="getList"
      :product_id="product_id"
      :client_system_id="client_system_id"
    />
  </div>
</template>

<script>
import Clipboard from "clipboard"; //点击复制文本内容
import no_img from "@/assets/images/no_img.png";
import {
  getGoodsList,
  delGoodsInfo,
  operatingGoodsInfo,
  updateRecommend,
  getGoodsClassAll,
} from "@/api/marketing/shop/index.js";
import GoodsCreate from "./GoodsCreate.vue";
import GoodsDetail from "./GoodsDetail.vue";
export default {
  data() {
    return {
      detail: false,
      add: false,
      check: false,
      cond: {
        page: 1,
        limit: 15,
      },
      tabList: [
        { key: null, value: "全部", num: 0 },
        { key: 1, value: "待上架", num: 0 },
        { key: 2, value: "已上架", num: 0 },
        { key: 3, value: "已下架", num: 0 },
        { key: 5, value: "已售罄", num: 0 },
      ],
      tab: "全部",
      list: [],
      page: 1,
      limit: 15,
      total: 0,
      no_img: no_img,
      shelves_num: {},
      status: {
        1: "待上架",
        2: "已上架",
        3: "已下架",
        4: "已删除",
        5: "已售罄",
      },
      goodsStatus: 0,
      product_id: 0,
      classList: [],
      seleclGoodsList: [],
    };
  },
  components: {
    GoodsCreate,
    GoodsDetail,
  },
  created() {
    this.getList();
    this.getGoodsClassAll();
  },
  props: {
    client_system_id: Number,
  },
  methods: {
    //复制功能
    copylogistics(data) {
      console.log(data);
      let clipboard = new Clipboard(".copy", {
        //绑定数据标签的类名
        text: function () {
          return data;
        },
      });
      clipboard.on("success", (e) => {
        this.$message({
          type: "success",
          message: "复制成功！",
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message({
          type: "success",
          message: "该浏览器或手机权限不支持复制功能",
        });

        // 释放内存
        clipboard.destroy();
      });
    },
    handleEdit(row) {
      this.product_id = row.integral_store_product_id;
      this.add = true;
    },
    handleCheck(row) {
      this.product_id = row.integral_store_product_id;
      this.add = true;
      this.check = true;
    },
    getGoodsClassAll() {
      getGoodsClassAll({ client_system_id: this.client_system_id }).then(
        (res) => {
          if (res.code === 1) {
            this.classList = res.data;
          }
        }
      );
    },
    handleActionType(command) {
      if (this.seleclGoodsList.length === 0) {
        this.$message({
          message: "请选择商品",
          type: "warning",
        });
      } else {
        if (command === 1) {
          operatingGoodsInfo({
            client_system_id: this.client_system_id,
            product_ids: this.seleclGoodsList.map((item) => {
              return item.integral_store_product_id;
            }),
            status: 2,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.getList();
            }
          });
        } else if (command === 5) {
          operatingGoodsInfo({
            client_system_id: this.client_system_id,
            product_ids: this.seleclGoodsList.map((item) => {
              return item.integral_store_product_id;
            }),
            status: 3,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.getList();
            }
          });
        } else if (command === 2) {
          this.$confirm("是否确定删除吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            delGoodsInfo({
              product_ids: this.seleclGoodsList.map((item) => {
                return item.integral_store_product_id;
              }),
            }).then((res) => {
              if (res.code === 1) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getList();
              }
            });
          });
        } else if (command === 3) {
          updateRecommend({
            product_ids: this.seleclGoodsList.map((item) => {
              return item.integral_store_product_id;
            }),
            is_recommend: 1,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.getList();
            }
          });
        }
      }
    },
    handleDel(row) {
      this.$confirm("是否确定删除该商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delGoodsInfo({
          product_ids: [row.integral_store_product_id],
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          }
        });
      });
    },
    updateRecommend(row) {
      updateRecommend({
        product_ids: [row.integral_store_product_id],
        is_recommend: row.is_recommend,
        client_system_id: row.client_system_id,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    handlePut(row) {
      operatingGoodsInfo({
        client_system_id: this.client_system_id,
        product_ids: [row.integral_store_product_id],
        status: 2,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    handleDown(row) {
      operatingGoodsInfo({
        client_system_id: this.client_system_id,
        product_ids: [row.integral_store_product_id],
        status: 3,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    handleReset() {
      this.cond = {
        page: 1,
        limit: 15,
        status: this.goodsStatus,
      };
      this.getList();
    },
    handleTab(item) {
      this.tab = item.value;
      this.goodsStatus = item.key;
      this.cond.status = item.key;
      this.getList();
    },
    getList() {
      this.product_id = 0;
      this.add = false;
      this.check = false;
      getGoodsList({
        ...this.cond,
        client_system_id: this.client_system_id,
      }).then((res) => {
        if (res.code === 1) {
          res.data.list.map((item) => {
            item.images = item.images.split(",")[0];
            if (item.sku) {
              item.sku.map((sku) => {
                if (sku.attribute !== "") {
                  sku.attribute = JSON.parse(sku.attribute);
                }
                sku.hierarchy = JSON.parse(sku.hierarchy);
                return sku;
              });
            }
            console.log(item.product_hierarchy_num);
            return item;
          });

          this.list = res.data.list;
          this.total = res.data.total;
          this.shelves_num = res.data.shelves_num;
          this.tabList[0].num = res.data.shelves_num.all;
          this.tabList[1].num = res.data.shelves_num.shelves_on;
          this.tabList[2].num = res.data.shelves_num.shelves_ing;
          this.tabList[3].num = res.data.shelves_num.shelves_elt;
          this.tabList[4].num = res.data.shelves_num.shelves_out;
        }
      });
    },
    handleSelectionChange(val) {
      this.seleclGoodsList = val;
    },
    handleSizeChange(val) {
      this.cond.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getList();
    },
    handleAddGoods() {
      this.add = true;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  overflow: hidden;
}
/deep/.card .el-form-item {
  margin-bottom: 0px;
}
.tab {
  display: flex;
  width: 100%;
  height: 50px;
  line-height: 50px;
  .tabs {
    width: 8%;
    text-align: center;
    .underLne {
      margin: -5px auto 0 auto;
      width: 18px;
      height: 4px;
      border-radius: 2px;
      opacity: 1;
      background: rgba(25, 145, 255, 1);
    }
  }
  .tabs:hover {
    cursor: pointer;
  }
}
.goodsImage {
  margin: 5px 0;
  width: 56px;
  height: 56px;
  img {
    width: 56px;
    height: 56px;
  }
}
</style>
