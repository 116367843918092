<template>
  <div>
    <div class="card" style="margin-bottom: 15px" v-if="!systemVisiable">
      <el-page-header @back="newGoBack" content="商城管理"></el-page-header>
    </div>
    <System v-if="systemVisiable" @hide="handleShop" />
    <Setup
      v-if="setUpVisiable"
      :client_system_id="client_system_id"
      :type="type"
      @hide="goBack"
      :title="title"
    />
    <Main v-if="mainVisiable" :client_system_id="client_system_id" />
  </div>
</template>

<script>
import Main from "./Main.vue";
import System from "./System.vue";
import Setup from "./Setup.vue";
export default {
  data() {
    return {
      systemVisiable: true,
      setUpVisiable: false,
      mainVisiable: false,
      client_system_id: 0,
      type: 0,
      title: "",
    };
  },
  components: {
    System,
    Setup,
    Main,
  },
  methods: {
    newGoBack(){
      this.systemVisiable = true;
      this.setUpVisiable = false;
      this.mainVisiable = false;
    },
    goBack() {
      this.systemVisiable = true;
      this.setUpVisiable = false;
    },
    handleShop(row) {
      this.client_system_id = row.client_system_id;
      this.type = row.type;
      this.title = row.title;
      if (row.type === 2) {
        this.systemVisiable = false;
        this.mainVisiable = true;
      } else if (row.type === 1) {
        this.systemVisiable = false;
        this.setUpVisiable = true;
      }
    },
  },
};
</script>
